import React from 'react'
import { getCurrentUser } from '../../../services/UsersData'

type Props = {}

export default function Finances({}: Props) {

    function createStripeConnectAccount() {
        getCurrentUser().then((user)=>{
            if(user){
                fetch(`http://localhost:4242/create-stripe-connect-account`, {
                    method: "POST",
                    headers: {
                    "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        userId: user.id,
                        userEmail: user.email,
                    }),
                })
                .then(res => {
                    if (res.ok) return res.json()
                    return res.json().then(json => Promise.reject(json))
                })
                .then(({ url }) => {
                    console.log(url);
                    window.location = url
                })
                .catch(e => {
                    console.error(e.error)
                })
            }
        })
    }

    function loginToStripeConnectAccount() {
        getCurrentUser().then((user)=>{
            if(user){
                fetch(`http://localhost:4242/login-to-stripe-connect-account`, {
                    method: "POST",
                    headers: {
                    "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        userId: user.id,
                        userEmail: user.email,
                    }),
                })
                .then(res => {
                    if (res.ok) return res.json()
                    return res.json().then(json => Promise.reject(json))
                })
                .then(({ url }) => {
                    console.log(url);
                    window.location = url
                })
                .catch(e => {
                    console.error(e.error)
                })
            }
        })
    }

    function addMoney() {
        getCurrentUser().then((user)=>{
            if(user){
                fetch(`http://localhost:4242/add-money`, {
                    method: "POST",
                    headers: {
                    "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        userId: user.id,
                        userEmail: user.email,
                    }),
                })
                .then(res => {
                    if (res.ok) return res.json()
                    return res.json().then(json => Promise.reject(json))
                })
                .catch(e => {
                    console.error(e.error)
                })
            }
        })
    }

    return (
        <div>
            <div>Finances</div>
            <button 
                onClick={()=>createStripeConnectAccount()}
                className={`w-fit text-xl md:text-base bg-main bg-essayGreen hover:bg-essayGreenDark duration-150 hover:duration-150 ease-in-out text-white font-bold py-[6px] px-3 rounded-lg my-auto `}
            >
                {"Utwórz konto Stripe Connect"}
            </button>
            <button 
                onClick={()=>loginToStripeConnectAccount()}
                className={`w-fit text-xl md:text-base bg-main bg-essayGreen hover:bg-essayGreenDark duration-150 hover:duration-150 ease-in-out text-white font-bold py-[6px] px-3 rounded-lg my-auto `}
            >
                {"Wejdź do konta Stripe Connect"}
            </button>
            <button 
                onClick={()=>addMoney()}
                className={`w-fit text-xl md:text-base bg-main bg-essayGreen hover:bg-essayGreenDark duration-150 hover:duration-150 ease-in-out text-white font-bold py-[6px] px-3 rounded-lg my-auto `}
            >
                {"Dodaj pieniądze do konta"}
            </button>
        </div>
    )
}
import React from "react";
import { FiEdit } from "react-icons/fi";
import { HiDocumentRemove } from "react-icons/hi";
import { Task } from "../../assets/types";

type Props = {
  tasks: Task[];
  fragment: boolean;
  editTask: (id: number) => void;
  addTask: () => void;
  removeTask: (id: number) => void;
};

const TaskList: React.FC<Props> = ({ fragment, tasks, editTask, addTask, removeTask }) => {
  return (
    <div className="flex flex-colm m-auto mt-4">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow border-b border-gray-200 sm:rounded-lg">
            <table className="divide-y divide-gray-200">
              <thead className="bg-gray-50 text-xs font-medium text-gray-500 ">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 w-2 text-left uppercase tracking-wider"
                  >
                    ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 w-4 text-left uppercase tracking-wider"
                  >
                    Task
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 w-8 text-left uppercase tracking-wider"
                  >
                    Fragment Author
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 w-16 text-left uppercase tracking-wider"
                  >
                    Fragment Title
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 w-16 text-left uppercase tracking-wider"
                  >
                    Fragment Content
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 w-16 text-left uppercase tracking-wider"
                  >
                    Fragment Source
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 w-2 text-left uppercase tracking-wider"
                  >
                    Source
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 w-16 text-left uppercase tracking-wider"
                  >
                    Subtype
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 w-16 text-left uppercase tracking-wider"
                  >
                    Product ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 w-16 text-left uppercase tracking-wider"
                  >
                    Owner
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Remove</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 text-sm text-gray-500">
                {tasks.map((task) => (
                  <tr key={task.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="w-2 overflow-clip">
                        {task.id}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="w-16 overflow-clip">
                        {task.task}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="w-16 overflow-clip">
                        {task.fragment_author}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="w-16 overflow-clip">
                        {task.fragment_title}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-pre-line">
                      <div className=" max-h-12 w-32 overflow-x-clip overflow-y-clip">
                        {task.fragment_content}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap overflow-clip">
                      <div className="w-16 overflow-clip">
                        {task.fragment_source}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap overflow-clip">
                      <div className="w-16 overflow-clip">
                        {task.task_source}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap overflow-clip">
                      <div className="w-16 overflow-clip">
                        {task.subtype?.name ?? "brak"}
                      </div>
                    </td>
                    <td className="px-6 py-4 w-16 whitespace-nowrap overflow-clip">
                      <div className="w-16 overflow-clip">
                        {task.product_id}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="w-16 overflow-clip">
                        {task.owner_id}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button
                        className="text-green-600 hover:text-green-900 flex gap-2"
                        onClick={() => editTask(task.id)}
                      >
                        <FiEdit className="h-5 w-5" aria-hidden="true" />
                        Edytuj
                      </button>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button
                        className="text-green-600 hover:text-green-900 flex gap-2"
                        onClick={() => removeTask(task.id)}
                      >
                        <HiDocumentRemove className="h-5 w-5" aria-hidden="true" />
                        Usuń
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-end bg-neutral-100 rounded-br-md rounded-bl-md p-2">
              <button
                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={() => addTask()}
              >
                Dodaj
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
)}

export default TaskList;
import React, { useState, useEffect } from 'react'
import Loading from '../../../layouts/Loading';
import EssayCard from '../../../components/EssayCard/EssayCard';
import { getProductsAll } from '../../../services/EssaysData';
import CardsLayout from '../../../layouts/CardsLayout';
import HeaderContainer from '../../../components/Outputs/HeaderContainer';
import SemiHeaderContainer from '../../../components/Outputs/SemiHeaderContainer';
import { getCurrentUser } from '../../../services/UsersData';

export default function BuyProduct() {

    const [products, setProducts] = useState<any[]>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getProductsAll().then((data)=>{
            if(data) {
                data.sort(function (a, b) {
                    return Number(a.generation) - Number(b.generation);
                });
                setProducts(data)
                setLoading(false);
            }
        });
    }, []);

    function handleChoose (id: number, props: {isQuick: boolean}) {

        getCurrentUser().then((user)=>{
            if(user){
                fetch(`http://localhost:4242/buy-product`, {
                    method: "POST",
                    headers: {
                    "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        userId: user.id,
                        item: { id: id, quantity: 1, isQuick: props.isQuick },
                    }),
                })
                .then(res => {
                    if (res.ok) return res.json()
                    return res.json().then(json => Promise.reject(json))
                })
                .then(({ url }) => {
                    console.log(url);
                    window.location = url
                })
                .catch(e => {
                    console.error(e.error)
                })
            }
        })

        /* pushEssay(id.toString(), props.isQuick).then(()=>{
            navigate("/student/write");
        }) */
    }

    if (loading)
    {
        return <Loading/>;
    }

    return (
        <>
            <HeaderContainer>
                Najpierw wybierz sobie pracę do napisania. Po zakupie pojawi się ona jako bilet w zakładce "Napisz".
            </HeaderContainer>
            <SemiHeaderContainer>
                Pamiętaj, że praca będzie oceniana zgodnie z typem egzaminu jaki zakupisz.
            </SemiHeaderContainer>
            <CardsLayout>
                {products?.map((product, i)=>(
                    <EssayCard 
                        key={i}
                        exam={product.exam} 
                        id={product.id}
                        generation={product.generation} 
                        subject={product.subject} 
                        level={product.level} 
                        action={()=>handleChoose(product.id, {isQuick: true})}
                        writingTime={product.max_time}
                        minWords={product.min_words}
                        type={product.type} 
                        price={product.price}
                        //reducedPrice={29.99}
                        cardFunction={"buy"}
                        actionText={"Kup"}
                    />
                ))}
            </CardsLayout>
        </>
    )
}

import { Subtype, Task } from "../assets/types";
import { supabase } from "./client";

export async function getTasksAll ( productId: number )
{
    try {
        const { error, status, data } = await supabase
            .from('tasks')
            .select("*")

        if (error && status !== 406) throw error;
        else if(data) {
            return data as Task[];
        }
    }
    catch (error)
    {
        console.error(error)
    }
}

export async function getTask ( task_id: number ) {
    
    try {
          
        let { data, error, status } = await supabase
            .from('tasks')
            .select('*, subtype:subtypes(*)')
            .eq("id", task_id)
            .single()
        
        if (error && status !== 406) throw error
        if (data) {
            return data as Task;
        }
    } catch (error: any) {
        console.error(error.message)
    }
  }

/*   export async function getJustTask ( task_id: number ) {
    
    try {
          
        let { data, error, status } = await supabase
            .from('justTasks')
            .select('*, subtype:subtypes(*)')
            .eq("id", task_id)
            .single()
        
        if (error && status !== 406) throw error 
        if (data) {
            return data as Task;
        }
    } catch (error: any) {
        console.error(error.message)
    }
  } */

export async function getTasksForProduct (product_id: number) {
    
    try {
          
        let { data, error, status } = await supabase
            .from('tasks')
            .select('*, subtype:subtypes(*)')
            .eq("product_id", product_id)
        
        if (error && status !== 406) throw error
        if (data) {
            return data as Task[];
        }
    } catch (error: any) {
        console.error(error.message)
    }
  }

/*   export async function getJustTasksForProduct (product_id: number) {
    
    try {
          
        let { data, error, status } = await supabase
            .from('justTasks')
            .select('*, subtype:subtypes(*)')
            .eq("product_id", product_id)
        
        if (error && status !== 406) throw error
        if (data) {
            return data as JustTask[];
        }
    } catch (error: any) {
        console.error(error.message)
    }
  } */

  export async function getTasksPublicForProduct (product_id: number) {
    
    try {
          
        let { data, error, status } = await supabase
            .from('tasks')
            .select('*, subtype:subtypes(*)')
            .match({"product_id": product_id, is_public: true})
        
        if (error && status !== 406) throw error
        if (data) {
            return data as Task[];
        }
    } catch (error: any) {
        console.error(error.message)
    }
  }

/*   export async function getJustTasksPublicForProduct (product_id: number) {
    
    try {
          
        let { data, error, status } = await supabase
            .from('justTasks')
            .select('*, subtype:subtypes(*)')
            .match({"product_id": product_id, is_public: true})
        
        if (error && status !== 406) throw error
        if (data) {
            return data as JustTask[];
        }
    } catch (error: any) {
        console.error(error.message)
    }
  } */

export async function getTasksFiltered ( productId: number, filter: {} )
{
    try {
        const { data, error, status } = await supabase
            .from('tasks')
            .select("*")
            .match(filter)
        if (error && status !== 406) throw error;
        else if(data) {
            console.log("Pobrane taski: ")
            console.table(data);
            return data as Task[];
        }
    }
    catch (error)
    {
        console.log(error)
    }
}

export async function pushTaskForProduct ( task_data: Partial<Task> )
{
    try {
        const { data, error, status } = await supabase
            .from('tasks')
            .insert(task_data)
            .select('*, subtype:subtypes(*)')
            .single()

        if (error && status !== 406) throw error;
        else if(data) {
            return data.id as number;
        }
    }
    catch (error)
    {
        console.log(error)
    }
}

/* export async function pushJustTaskForProduct ( props: Partial<JustTask> )
{
    try {
        const { data, error, status } = await supabase
            .from('justTasks')
            .insert(props)
            .select('*, subtype:subtypes(*)')
            .single()

        if (error && status !== 406) throw error;
        else if(data) {
            return data.id as number;
        }
    }
    catch (error: any)
    {
        console.error(error.message)
    }
} */

/* export async function updateJustTask( task_id: number, updatedData: any) {
    try {
        const { error, status } = await supabase
            .from('justTasks')
            .update(updatedData)
            .eq('id', task_id)
            .select('*')
        if (error && status !== 406) throw error;
    }
    catch (error)
    {
        console.error(error)
    }
} */

export async function updateTask( task_id: number, updatedData: Partial<Task>) {
    try {
        const {  error, status } = await supabase
            .from('tasks')
            .update(updatedData)
            .eq('id', task_id)
            .select('*')
        if (error && status !== 406) throw error;
    }
    catch (error)
    {
        console.error(error)
    }
}

export async function deleteTask( task_id: number ) {
    try {
        const { error, status } = await supabase
            .from('tasks')
            .delete()
            .match({'id': task_id})
        if (error && status !== 406) throw error;
    }
    catch (error)
    {
        console.log(error)
    }
}

/* export async function deleteJustTask( task_id: number ) {
    try {
        const { error, status } = await supabase
            .from('justTasks')
            .delete()
            .match({'id': task_id})
        if (error && status !== 406) throw error;
    }
    catch (error)
    {
        console.error(error)
    }
} */

export async function getSubtypes()
{
    try {
        const { error, data, status } = await supabase
            .from("subtypes")
            .select("*")

        if (error && status !== 406) throw error;
        if(data) return data as Subtype[];

    } catch (error) {
        console.error(error)
    }
}


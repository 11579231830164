import React, { useEffect, useState } from 'react'
import { Essay } from '../../assets/types';
import { useWindowSize } from '../../hooks/useWindowsSize';
import EssayDashboardGraphs from './EssayDashboardGraphs';
import EssayTaskDisplay from './EssayTaskDisplay';
import FragmentDisplay from './FragmentDisplay';

type Props = {
    essay: Essay;
    content: string;
    role: string;
    points?: number;
}

export default function EssayDashboard({essay, content, role, points}: Props) {
    
    const size = useWindowSize();
    const [time, setTime] = useState(essay.writing_time)
    const wordsArray = content.trim().split(/\s+/);
    const [words, setWords] = useState(wordsArray.length);

    useEffect(() => {
        if (!content) {
            setWords(0);
        } else {
            setWords(wordsArray.length);
        }
    }, [content, wordsArray.length]);

    useEffect(()=>{

    }, [size])
    
    if( size.width > 768 )
    {
        return (
            <div className='flex flex-col w-[350px] md:w-[700px] mb-2 gap-2'>
                <div className='flex flex-row gap-2'>
                    <EssayTaskDisplay 
                        essay_id={essay.id}
                        header={essay.task.subtype.name} 
                        content={essay.task.task} 
                        source={essay.task.task_source}
                        role={role}
                    />
                    <EssayDashboardGraphs 
                        essay_id={essay.id}
                        ptsThreshold={(essay.product.threshold/100)*essay.product.max_exam_points}
                        minWords={essay.product.min_words}
                        maxWords={essay.product.max_words}
                        maxTime={essay.product.max_time}
                        words={words}
                        time={time}
                        setTime={setTime}
                        role={role}
                        points={points ?? 0}
                        maxPoints={essay.product.max_points}
                    />
                </div>
                {essay.product.is_fragment_required ? (
                <FragmentDisplay 
                    header={essay.task.fragment_title + " - " + essay.task.fragment_author} 
                    content={essay.task.fragment_content} 
                    source={essay.task.fragment_source} />
                ) : ""}
            </div>
        )
    } else {
        return (
            <div className='flex flex-col w-[350px] md:w-[700px] mb-2 gap-2'>
                <div className='flex flex-col gap-2'>
                    <EssayDashboardGraphs 
                        essay_id={essay.id}
                        ptsThreshold={(essay.product.threshold/100)*essay.product.max_exam_points}
                        minWords={essay.product.min_words}
                        maxWords={essay.product.max_words}
                        maxTime={essay.product.max_time}
                        words={words}
                        time={time}
                        setTime={setTime}
                        role={role}
                        points={points ?? 0}
                        maxPoints={essay.product.max_points}
                    />
                    <EssayTaskDisplay 
                        essay_id={essay.id} 
                        header={essay.task.subtype.name} 
                        content={essay.task.task} 
                        source={essay.task.task_source} 
                        role={role}
                    />
                </div>
                {essay.product.is_fragment_required ? (
                <FragmentDisplay 
                    header={essay.task.fragment_title + " - " + essay.task.fragment_author} 
                    content={essay.task.fragment_content} 
                    source={essay.task.fragment_source} />) : ""}
            </div>
        )
    }
}
import React from 'react'
import { HiArrowNarrowUp } from 'react-icons/hi'

type Props = {}

export default function CentileInfo(props: Props) {
  return (
    <div className='h-full w-full flex flex-col justify-center'>
      <div>
        <div className='text-2xl md:text-3xl font-semibold m-auto text-left'>
          Jesteś w top <span className='text-2xl md:text-4xl font-extrabold text-green-500'>65%<HiArrowNarrowUp className={"inline-block -ml-2 -mr-2 pb-2"} size={30}/></span>
        </div>
        <div className='text-2xl md:text-3xl font-semibold m-auto text-left'>
          Ostatni wynik <span className='text-2xl md:text-4xl font-extrabold text-green-500'>75%<HiArrowNarrowUp className={"inline-block -ml-2 -mr-2 pb-2"} size={30}/></span>
        </div>
        <div className='text-2xl md:text-3xl font-semibold m-auto text-left'>
          Ostatni wynik <span className='text-2xl md:text-4xl font-extrabold text-green-500'>75%<HiArrowNarrowUp className={"inline-block -ml-2 -mr-2 pb-2"} size={30}/></span>
        </div>
      </div>
    </div>
  )
}
import React, { useEffect, useState } from 'react'
import DashboardTile from '../../../components/Tile/DashboardTile'
import BlankTileContent from '../../../components/Tile/BlankTileContent'
import Loading from '../../../layouts/Loading';
import RingGraph from '../../../components/Graphs/RingGraph';
import { getCurrentUserData } from '../../../services/UsersData';
import Greetings from '../../../components/Outputs/Greetings';
import TilesLayout from '../../../layouts/TilesLayout';

export default function TeacherDashboard() {

    const [name, setName] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getCurrentUserData().then((user)=>{
            if(user){
                setName(user.name);
                setLoading(false);
            }
        })
    })

    if (loading) return <Loading />;
    return (
        <>
            <Greetings name={name}/>
            <TilesLayout>
                <DashboardTile colspan={1} rowspan={1} children={<RingGraph values={[150,213,70]}/>} />
                <DashboardTile colspan={1} rowspan={1} children={<BlankTileContent />} />
                <DashboardTile colspan={1} rowspan={1} children={<BlankTileContent />} />
                <DashboardTile colspan={1} rowspan={1} children={<BlankTileContent />} />
                <DashboardTile colspan={1} rowspan={1} children={<BlankTileContent />} />
                <DashboardTile colspan={2} rowspan={2} children={<BlankTileContent />} />
                <DashboardTile colspan={1} rowspan={2} children={<BlankTileContent />} />
                <DashboardTile colspan={1} rowspan={1} children={<BlankTileContent />} />
                <DashboardTile colspan={1} rowspan={1} children={<BlankTileContent />} />
                <DashboardTile colspan={1} rowspan={1} children={<BlankTileContent />} />
                <DashboardTile colspan={1} rowspan={1} children={<BlankTileContent />} />
                <DashboardTile colspan={1} rowspan={1} children={<BlankTileContent />} />
                <DashboardTile colspan={1} rowspan={1} children={<BlankTileContent />} />
                <DashboardTile colspan={2} rowspan={2} children={<BlankTileContent />} />
                <DashboardTile colspan={1} rowspan={1} children={<BlankTileContent />} />
                <DashboardTile colspan={2} rowspan={2}>
                    <BlankTileContent />
                </DashboardTile>
                <DashboardTile colspan={2} rowspan={2}>
                    <BlankTileContent />
                </DashboardTile>
            </TilesLayout>
        </>
    )
}

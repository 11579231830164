import React from 'react'
import { fallbackBgColor, fallbackColor } from '../../data/constants';

type Props = {
    bgColor:string | undefined;
    color:string | undefined;
    exam:string;
    generation:string;
}

export default function CardHeader(props: Props) {
  return (
    <div 
        className='relative rounded-t-2xl h-20 md:h-14 -m-4 mb-3 pt-2 flex flex-row items-start justify-center select-none pointer-events-none' 
        style={{background: props.bgColor??fallbackBgColor}}>
        <div 
            className='absolute bottom-0 uppercase left-2 opacity-90 font-black text-2xl md:text-xl' 
            style={{color: props.color??fallbackColor}}>{props.exam}</div>
        <span 
            className='absolute bottom-0 right-2 opacity-90 font-black text-7xl md:text-5xl' 
            style={{color: props.color??fallbackColor}}>{" " + props.generation}</span>
    </div>
  )
}

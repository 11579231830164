import { Navigate, Outlet } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Loading from "../layouts/Loading";
import { getCurrentUserRole } from "../services/UsersData";

type Props = {
    role: string;
    redirectPath: string;
}

export default function RoleRoutes(props: Props) {

    const [loading, setLoading] = useState(true);
    const [userRole, setUserRole] = useState("");

    useEffect(()=>{
        getCurrentUserRole().then((role)=>{
            if(role) {
                setUserRole(role)
                setLoading(false);
            }
        })
    })

    if ( loading ) return <Loading />
    if ( userRole === props.role || userRole === "admin" ) return <Outlet />;
    else {
        return <Navigate to={props.redirectPath} replace />;
    }
}
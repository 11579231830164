import React from 'react'
import { FiFolder } from 'react-icons/fi'
import { IconType } from 'react-icons/lib';
import { useNavigate } from 'react-router-dom';

type Props = {
    icon: IconType;
}

export default function SidebarButton({icon}: Props) {

    return (
        <>
            <div>
                {React.createElement(icon, { size: "32" })}
            </div>
        </>
    )
}

import React from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from '../../../components/Sidebar/Sidebar'

type Props = {}

export default function Admin({}: Props) {

    return (
        <>
            <div className='flex'>
                <Sidebar role={"admin"}/>
                <div className=' pb-28 md:pb-0 pl-0 md:pl-16 w-full'>
                    <Outlet/>
                </div>
            </div>
        </>
    )
}
import React, { useEffect, useState } from 'react'
import { getEssayForCurrentTeacher } from '../services/EssaysData';
import { useParams } from 'react-router-dom';
import Loading from './Loading';
import GradingPaper from './GradingPaper';
import { useWindowSize } from '../hooks/useWindowsSize';
import PaperBg from './PaperBg';
import AnnotationManager from './AnnotationManager';
import { deleteHighlight, getHighlights, pushHighlight, updateHighlightComment } from '../services/HighlightsData';
import { highlightType, span_position } from '../assets/types';

const predef = {
    highlight: {
        color: "rgba(255, 0, 0, 0.5)",
        colorOnFocus: "rgba(255, 50, 50, 0.7)",
    },
}
  
export class Highlighter {

    essay_id: string = "";
    product_id: string = "";
    teacher_id: string = "";
    
    highlights: highlightType[];
    text: string = "";

    tempHighlight: highlightType|null = null;
    lastHighlighyId: string | null = null;

    constructor(text: string, teacher_id: string, product_id: string, essay_id: string) {
        this.highlights = [];
        this.text = text;
        this.teacher_id = teacher_id;
        this.product_id = product_id;
        this.essay_id = essay_id;
    }

    createHighlight( wrapper: HTMLElement, text: string, color: string, id: string  ) { 
        const highlightSpan = document.createElement('span');
        highlightSpan.id = "H" + id;
        highlightSpan.style.backgroundColor = color;
        highlightSpan.style.paddingTop = "2px"; // 5px
        highlightSpan.style.paddingBottom = "2px"; // 0px
        highlightSpan.style.borderRadius = "2px";

        highlightSpan.appendChild(document.createTextNode(text));
        return highlightSpan;
    }

    createTextWrapper( wrapperTemplate: HTMLElement, id: string ) {
        let wrapper = document.createElement('div');
        wrapper.id = id;
        wrapper.style.cssText = wrapperTemplate.style.cssText;
        wrapper.className = wrapperTemplate.className;
        return wrapper;
    }

    handleRequest( textarea: HTMLTextAreaElement ) {
        const start = textarea.selectionStart, end = textarea.selectionEnd;
        const selectedText = textarea.value.substring(start, end);
        const id = Date.now().toString();

        const highlight = {id: id, start: start, end: end, type: "", selected_text: selectedText, comment: "", position: null as span_position | null, color: predef.highlight.color, color_on_focus: predef.highlight.colorOnFocus } as highlightType;
        highlight.position = this.printHighlight(highlight);
        this.tempHighlight = highlight;
    }

    clearHighlights() {
        
        this.highlights.forEach((highlight)=>{
            const element = document.getElementById(highlight.id);
            if (element) {
                element.remove();
            }
        })

        /// Could be optimized
    }

    printHighlight(highlight: highlightType) {
        const textarea = document.getElementById("selectable") as HTMLTextAreaElement;
        const wrapper = this.createTextWrapper(document.getElementById("wrapper-template") as HTMLElement, highlight.id);
        
        wrapper.appendChild(document.createTextNode(textarea.value.substring(0, highlight.start)));
        const span = this.createHighlight(wrapper, highlight.selected_text, highlight.color, highlight.id);
        wrapper.appendChild(span);
        wrapper.appendChild(document.createTextNode(textarea.value.substring(highlight.end, textarea.value.length)));

        textarea.parentElement?.prepend(wrapper);
        return { top: span.offsetTop, left: span.offsetLeft, width: span.getBoundingClientRect().width };
    }

    printHighlights() {
        this.highlights.forEach((highlight)=>{
            this.printHighlight(highlight);
        })
    }

    loadHighlights() {
        return getHighlights(Number(this.essay_id)).then((highlights)=>{
            this.highlights = highlights;
            highlights.forEach((highlight)=>{
                highlight.position = this.printHighlight(highlight);
            })
            window.dispatchEvent(new Event("reprint_annotations"));
        })
    }

    setupTextarea() {
        const textarea = document.getElementById("selectable") as HTMLTextAreaElement;
        const paperbg = document.getElementById("paper-bg") as HTMLElement;
        textarea.style.height = textarea.scrollHeight + "px";
        paperbg.style.height = textarea.style.height /* + 64 */ + "px";
    }

    handlePopup (action: string) {
        if(!this.tempHighlight) return;
        if (action === "Delete") {
            document.getElementById(this.tempHighlight?.id)?.remove(); 
            this.tempHighlight = null;
        } else if (this.tempHighlight) {
            this.tempHighlight.type = action;
            this.highlights.push(this.tempHighlight);
            pushHighlight( this.essay_id, this.teacher_id, this.tempHighlight);
            window.dispatchEvent(new Event("reprint_annotations"));
            this.lastHighlighyId = this.tempHighlight.id;
            window.dispatchEvent(new Event("focus_on_last_highlight"));
        }
        this.tempHighlight = null;
    }

    sortHighlights() {
        this.highlights.sort((a, b) => {
            if (!a.position || !b.position) return 0;
            return a.position.top - b.position.top;
        })
    }

    reprintAnnotations() {
        this.sortHighlights();
    }

    remove(id: string) {
        deleteHighlight(Number(id), Number(this.essay_id));
        this.removeHighlightLocally(id);
    }

    removeHighlightLocally(id: string) {
        const highlight = this.highlights.find((highlight)=>{return highlight.id === id});
        if (highlight) {
            const element = document.getElementById("H" + highlight.id);
            if (element) {  element.remove(); }
            this.highlights = this.highlights.filter((highlight)=>{return highlight.id !== id});
        } else { throw new Error("Highlight not printed or is not in database."); }
        window.dispatchEvent(new Event("reprint_annotations"));
    }

    /**
     * Updates the comment in the database.
     */
    updateComment(id: string, comment: string) {
        updateHighlightComment(Number(id), Number(this.essay_id), comment);
    }

    /**
     * Warning: Needs to be printed first!
     */
    focusHighlight(id: string) {
        const span = document.getElementById('H'+id) as HTMLSpanElement;
        const annotationTextarea = document.getElementById('comment-' + id) as HTMLTextAreaElement;
        const highlightData = this.highlights.find((highlight)=>{return highlight.id === id});
        console.log("focusHighlight")

        if (span && highlightData && annotationTextarea) {
            span.style.padding = "4px";
            span.style.borderRadius = "4px";
            span.style.backgroundColor = highlightData.color_on_focus;                        ////// REPLACE ON PROD
            annotationTextarea.focus();
        } else { throw new Error("Highlight not printed or is not in database."); }
    }

    /**
     * Warning: Needs to be printed first!
     */
    unfocusHighlight(id: string) {
        const span = document.getElementById('H'+id) as HTMLSpanElement;
        const annotationTextarea = document.getElementById('comment-' + id) as HTMLTextAreaElement;
        const highlightData = this.highlights.find((highlight)=>{return highlight.id === id});

        if (span && highlightData && annotationTextarea) {
            span.style.padding = "2px";
            span.style.borderRadius = "2px";
            span.style.backgroundColor = highlightData.color;                               ////// REPLACE ON PROD
            annotationTextarea.blur();
        } else { throw new Error("Highlight not printed or is not in database."); }
    }
}

export default function TestGrading() {

    const { id } = useParams();
    const [ loading, setLoading ] = useState(true);
    const [ second_loading, setSecondLoading ] = useState(true);
    const size = useWindowSize();
    const [ highlighter, setHighlighter ] = useState(null as Highlighter|null);
    const [ showPopup, setShowPopup ] = useState(false);
    const [ showAnnotations, setShowAnnotations ] = useState(false);

    function handleFocus () {
        if(!highlighter) { console.error("No highlighter"); return; }
        highlighter?.focusHighlight(highlighter.lastHighlighyId as string);
    }

    useEffect(() => {
        getEssayForCurrentTeacher(id ?? "").then((essay) => {
            if(essay) {
                const new_highlighter = new Highlighter(essay.content, essay.teacher_id, essay.product.id.toString(), essay.id.toString())
                setHighlighter(new_highlighter);
                setLoading(false);
                new_highlighter.loadHighlights().then(()=>{
                    setSecondLoading(false);
                })
            }
        })
    }, [id])

    useEffect(() => {
        if (!highlighter || loading) return;
        highlighter.setupTextarea();
        highlighter.clearHighlights();
        highlighter.printHighlights();
        // eslint-disable-next-line
    }, [size])

    useEffect(() => {
        window.addEventListener("focus_on_last_highlight", handleFocus);
        return () => window.removeEventListener("focus_on_last_highlight", handleFocus);
        // eslint-disable-next-line
    }, []);

    if (loading) { return <Loading/>; }
    return (
        <>
            { second_loading ? <div className='z-50'>
                <Loading/>
            </div> : "" }
            <div className='flex-row flex gap-1 h-fit '>
                <div>
                    {/* A component combining Annotations components with a use of useEffect waiting for a leftAnnotations hook change */}
                    <AnnotationManager highlighter={highlighter} side='left' />
                </div>
                <PaperBg>
                    <GradingPaper 
                        highlighter={highlighter} 
                        showPopup={showPopup} 
                        setShowPopup={setShowPopup} 
                        showAnnotations={showAnnotations} 
                        setShowAnnotations={setShowAnnotations} 
                    />
                </PaperBg>
                <div>
                    {/* rightAnnotations */}
                    <AnnotationManager highlighter={highlighter} side='right' />
                </div>
            </div>
        </>
    )
}

import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Loading from '../../../layouts/Loading';
import EssayCard from '../../../components/EssayCard/EssayCard';
import { getEssaysReservedByCurrentUser } from '../../../services/EssaysData';
import CardsLayout from '../../../layouts/CardsLayout';
import HeaderContainer from '../../../components/Outputs/HeaderContainer';
import { Essay } from '../../../assets/types';

export default function ChooseGrade() {

    const [essays, setEssays] = useState<Essay[]>([] as Essay[]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        getEssaysReservedByCurrentUser().then((items)=>{
            if(items) {
                setEssays(items)
                setLoading(false);
            }
        });
    }, []);

    function handleChoose (eid: number) {
        navigate (`/teacher/grade/${eid}`);
    }

    if (loading)
    {
        return <Loading/>;
    }

    return (
        <>
            <HeaderContainer>
                Tutaj znajdują się Twoje aktualne prace. Sprawdź je w wyznaczonym czasie albo zwróć je innym egzaminatorom.
            </HeaderContainer>
            <CardsLayout>
                {essays?.map((essay, i)=>{
                    return(
                        <EssayCard 
                            key={i}
                            exam={essay.product.exam} 
                            generation={essay.product.generation} 
                            id={essay.id} 
                            subject={essay.product.subject} 
                            level={essay.product.level} 
                            action={()=>handleChoose(essay.id)}
                            type={essay.task.subtype.name} 
                            words={essay.content.split(" ").length}
                            cardFunction={"grade"}
                            deadline={Date.parse(essay.started_grading_time)+essay.product.reservation_period*3600*1000}
                            chosenOn={Date.parse(essay.started_grading_time)}
                            actionText={"Oceń"}
                        />
                    )})}
            </CardsLayout>
        </>
    )
}

import React from 'react'

type Props = {}

export default function MostCommonError({}: Props) {
  return (
    <div className='h-full w-full text-center flex flex-col justify-center'>
      <div>
        <div className='h-28 w-28 m-auto md:h-32 md:w-32 text-white bg-red-700 text-6xl md:text-7xl font-extrabold rounded-3xl text-center flex flex-col justify-center'>
          <div>
            A
          </div>
        </div>
        <div className='mt-2 text-sm md:text-lg font-semibold m-auto text-center'>
          Najczęstszy błąd
        </div>
      </div>
    </div>
  )
}
import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider } from '../src/context/AuthContext';

import Home from '../src/pages/public/Home'
import SignIn from '../src/pages/public/SignIn';
import SignUp from '../src/pages/public/SignUp';
import Error from '../src/pages/public/Error';
import PasswordRecovery from '../src/pages/public/PasswordRecovery';
import ResetPassword from '../src/pages/public/ResetPassword';
import ProtectedRoute from '../src/routes/ProtectedRoute';
import Student from './pages/private/student/Student';
import Teacher from './pages/private/teacher/Teacher';
import GradingContainer from './layouts/GradingContainer';
import WritingContainer from './layouts/WritingContainer';
import ChooseWrite from './pages/private/student/ChooseWrite';
import ChooseGrade from './pages/private/teacher/ChooseGrade';
import ReserveEssay from './pages/private/teacher/ReserveEssay';
import StudentDashboard from './pages/private/student/StudentDashboard';
import TeacherDashboard from './pages/private/teacher/TeacherDashboard';
import ViewPastEssays from './pages/private/student/ViewPastEssays';
import BuyProduct from './pages/private/student/BuyProduct';
import StudentSettings from './pages/private/student/StudentSettings';
import ViewCheckedEssays from './pages/private/teacher/ViewCheckedEssays';
import TeacherSettings from './pages/private/teacher/TeacherSettings';
import Finances from './pages/private/teacher/Finances';
import BeforeYouProceedStudent from './pages/private/student/BeforeYouProceedStudent';
import Tasks from './pages/private/admin/Tasks';
import Admin from './pages/private/admin/Admin';
import RoleRoutes from './routes/RoleRoutes';
import EssayTasks from './pages/private/admin/EssayTasks';
import EditTask from './pages/private/admin/EditTask';
import TeacherViewContainer from './layouts/TeacherViewContainer';
import StudentViewContainer from './layouts/StudentViewContainer';

import Time from './components/Time';
import Clock from './components/Experimental/Clock';
import TestGrading from './layouts/TestGrading';

export default function App() {
    return (
        <Routes>
            {/* Public paths */}
            <Route path="/"                     element={<Home />}/>
            <Route path="/signin"               element={<SignIn />} />
            <Route path="/password-recovery"    element={<PasswordRecovery />} />
            <Route path="/reset-password"       element={<ResetPassword />} />
            <Route path="/signup"               element={<SignUp />} />
            <Route path="/error"                element={<Error />} />
            <Route path="/home"                 element={<Navigate to="/" replace />} />
            <Route path="*"                     element={<Navigate to="/error" replace />} />

            {/* Test paths */}
            <Route path="/test"                 element={<Time />}/>
            <Route path="/test2"                element={<Clock />}/>

            {/* Protected paths */}
            <Route element={<ProtectedRoute redirectPath={"/"}/>}>
                <Route element={<RoleRoutes role="student" redirectPath={"/"}/>}>
                    <Route path="/student/" element={<Student />}>
                        <Route path=""                              element={<StudentDashboard />} />
                        <Route path="buy"                           element={<BuyProduct />} />
                        <Route path="write"                         element={<ChooseWrite />} />
                        <Route path="write/:id"                     element={<WritingContainer />} />
                        <Route path="write/before-you-proceed/:id"  element={<BeforeYouProceedStudent />} />
                        <Route path="view"                          element={<ViewPastEssays />} />
                        <Route path="view/:id"                      element={<StudentViewContainer />} />
                        <Route path="settings"                      element={<StudentSettings />} />
                        <Route path="*"                             element={<Navigate to="" replace />} />
                        <Route path="test/:id"                      element={<TestGrading />}/>
                    </Route>
                </Route>
                <Route element={<RoleRoutes role="teacher" redirectPath={"/"}/>}>
                    <Route path="/teacher/" element={<Teacher />}>
                        <Route path=""             element={<TeacherDashboard />} />
                        <Route path="reserve"      element={<ReserveEssay />} />
                        <Route path="grade"        element={<ChooseGrade />} />
                        <Route path="grade/:id"    element={<GradingContainer />} />
                        <Route path="view"         element={<ViewCheckedEssays />} />
                        <Route path="view/:id"     element={<TeacherViewContainer />} />
                        <Route path="view/:id"     element={<GradingContainer />} />
                        <Route path="finances"     element={<Finances />} />
                        <Route path="settings"     element={<TeacherSettings />} />
                        <Route path="*"            element={<Navigate to="" replace />} />
                    </Route> 
                </Route>
                <Route element={<RoleRoutes role="admin" redirectPath={"/"}/>}>
                    <Route path="/admin/" element={<Admin />}>
                        <Route path=""                              element={<TeacherDashboard />} />
                        <Route path="buy"                           element={<BuyProduct />} />
                        <Route path="tasks"                         element={<Tasks />} />
                        <Route path="tasks/:id"                     element={<EssayTasks />} />
                        <Route path="tasks/edit/:id"                element={<EditTask />} />
                        <Route path="write"                         element={<ChooseWrite />} />
                        <Route path="write/:id"                     element={<WritingContainer />} />
                        <Route path="write/before-you-proceed/:id"  element={<BeforeYouProceedStudent />} />
                        <Route path="reserve"                       element={<ReserveEssay />} />
                        <Route path="grade"                         element={<ChooseGrade />} />
                        <Route path="grade/:id"                     element={<GradingContainer />} />
                        <Route path="view"                          element={<ViewCheckedEssays />} />
                        <Route path="view/:id"                      element={<GradingContainer />} />
                        <Route path="finances"                      element={<Finances />} />
                        <Route path="settings"                      element={<TeacherSettings />} />
                        <Route path="*"                             element={<Navigate to="" replace />} />
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
}
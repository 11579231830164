import React, { useState } from 'react'
import { useNavigate } from "react-router-dom"
import { useAuth } from '../../context/AuthContext'
import { getCurrentUserRole } from '../../services/UsersData';

const SignIn = () => {

    const { signIn } = useAuth();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();

    const handleSignIn = async (event: any) => {
        event.preventDefault();
        setLoading(true);
        try {
            const {error} = await signIn({ email, password })
            if (error) { throw error; }
            getCurrentUserRole().then((role)=>{
                if(role) navigate("/"+role);
                else throw new Error("Rola niezdefiniowana");
            })
        } catch (error:any) {
            console.error(error.message);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
            <div className="w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-xl">
                <h1 className="text-3xl font-semibold text-center text-purple-700 underline">
                    Zaloguj się
                </h1>
                <form onSubmit={handleSignIn} className="mt-6">
                    <div className="mb-2">
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder='Adres email'
                            className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                    </div>
                    <div className="mb-2">
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder='Hasło'
                            className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                    </div>
                    <p className="mt-8 text-xs font-light text-center text-gray-700">
                        {" "}
                        Nie pamiętasz hasła?{" "}
                        <a
                            href="/password-recovery"
                            className="font-medium text-purple-600 hover:underline"
                        >
                            Zresetuj je!
                        </a>
                    </p>
                    <div className="mt-6">
                        <button disabled={loading} className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-purple-700 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600">
                            ZALOGUJ SIĘ
                        </button>
                    </div>
                </form>

                <p className="mt-8 text-xs font-light text-center text-gray-700">
                    {" "}
                    Nie masz jeszcze konta?{" "}
                    <a
                        href="/signup"
                        className="font-medium text-purple-600 hover:underline"
                    >
                        Zarejestruj się!
                    </a>
                </p>
            </div>
        </div>
    )
}

export default SignIn;
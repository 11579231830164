import React, { useEffect } from 'react';
import { HiOutlineClipboardList, HiOutlineCurrencyDollar, HiOutlineDocumentDuplicate, HiOutlineDocumentSearch, HiOutlineDocumentText, HiOutlineLogout, HiOutlineShoppingCart, HiSearch } from 'react-icons/hi';
import SidebarButton from './SidebarButton';
import { RiSettings4Line, RiTaskLine } from "react-icons/ri";
import SidebarMobileButton from './SidebarMobileButton';
import { Tooltip } from 'antd';
import { useWindowSize } from '../../hooks/useWindowsSize';
import { usePwa } from '../../hooks/usePwa';
import { useNavigate } from 'react-router-dom';
import logo from '../../images/logo2.png';
import { useAuth } from '../../context/AuthContext';

type Props = {
    role: string;
}

export default function Sidebar({role}: Props) {

    const { width } = useWindowSize();
    const { signOut } = useAuth();
    const isPwa = usePwa();

    useEffect(()=>{

    },[isPwa, width])

    const studentOptions = [
        { label: "Panel" ,icon: HiOutlineClipboardList, link: "/student" },
        { label: "Kup" ,icon: HiOutlineShoppingCart, link: "/student/buy" },
        { label: "Napisz" ,icon: HiOutlineDocumentText, link: "/student/write" },
        { label: "Wysłane" ,icon: HiOutlineDocumentSearch, link: "/student/view" },
        { label: "Ustawienia" ,icon: RiSettings4Line, link: "/student/settings" },
    ]
    const teacherOptions = [
        { label: "Panel" ,icon: HiOutlineClipboardList, link: "/teacher" },
        { label: "Wybierz" ,icon: HiSearch, link: "/teacher/reserve" },
        { label: "Oceń" ,icon: HiOutlineDocumentText, link: "/teacher/grade" },
        { label: "Zrobione" ,icon: HiOutlineDocumentDuplicate, link: "/teacher/view" },
        { label: "Finanse", icon: HiOutlineCurrencyDollar, link: "/teacher/finances"},
        { label: "Ustawienia" ,icon: RiSettings4Line, link: "/teacher/settings" },
    ]
    const adminOptions = [
        { label: "Panel" ,icon: HiOutlineClipboardList, link: "/admin" },
        { label: "Polecenia" ,icon: RiTaskLine, link: "/admin/tasks" },
        { label: "Kup" ,icon: HiOutlineShoppingCart, link: "/student/buy" },
        { label: "Napisz" ,icon: HiOutlineDocumentText, link: "/student/write" },
        { label: "Wysłane" ,icon: HiOutlineDocumentSearch, link: "/student/view" },
        { label: "Wybierz" ,icon: HiSearch, link: "/teacher/reserve" },
        { label: "Oceń" ,icon: HiOutlineDocumentText, link: "/teacher/grade" },
        { label: "Zrobione" ,icon: HiOutlineDocumentDuplicate, link: "/teacher/view" },
        { label: "Finanse", icon: HiOutlineCurrencyDollar, link: "/teacher/finances"},
        { label: "Ustawienia", icon: RiSettings4Line, link: "/admin/settings"}
    ]

    const options = role === "admin" ? adminOptions : role==="teacher" ? teacherOptions : studentOptions;
    const navigate = useNavigate();

    if( role === "" ) return <></>
    if(width <= 768)
    {
        return (
            <div className={`${isPwa ? "pb-10" : ""} fixed left-0 bottom-0 flex w-full z-[2000] bg-neutral-200 dark:bg-neutral-800 text-black dark:text-white border-neutral-300 dark:border-neutral-600 border-t`}>
                <div className='flex flex-row justify-evenly w-full sm:w-3/4 sm:mx-auto h-full'>
                    <>
                        {options.map((option, i)=>(
                            <SidebarMobileButton key={i} icon={option.icon} link={option.link}/>
                        ))}
                    </>
                </div>
            </div>
        )
    } else {
        return (
            <div className='w-16 h-full fixed left-0 top-0 z-[2000] flex flex-col p-2 bg-neutral-200 dark:bg-neutral-800 text-black dark:text-white border-neutral-300 dark:border-neutral-600 border-r'>
                <Tooltip title={"Strona główna"} showArrow={false} placement={"right"}>
                    <div className='mt-8' onClick={()=>{navigate("/")}}>
                        <img alt='Logo' src={logo} />
                    </div>
                </Tooltip>
                <div className='flex flex-col gap-1 my-auto mt-8'>
                    <>{options.map((option, i)=>(
                        <Tooltip key={i} title={option.label} showArrow={false} placement={"right"}>
                            <div className='p-2 rounded-lg hover:bg-neutral-50 hover:dark:bg-neutral-600 hover:text-essayGreen hover:dark:text-essayGreenLight duration-150 hover:duration-150 ease-in-out' onClick={()=>{navigate(option.link)}}>
                                <SidebarButton icon={option.icon}/>
                            </div>
                        </Tooltip>
                    ))}</>
                </div>
                <Tooltip title={"Wyloguj się"} showArrow={false} placement={"right"}>
                    <div className='p-2 mb-8 mt-auto rounded-lg hover:text-red-800 hover:dark:text-red-600 hover:dark:bg-neutral-600 duration-150 hover:duration-150 ease-in-out' onClick={()=>{signOut().then(()=>{navigate("/")})}}>
                        <SidebarButton icon={HiOutlineLogout}/>
                    </div>
                </Tooltip>
            </div>
        );
    }
};
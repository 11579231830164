import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Loading from '../../../layouts/Loading';
import EssayCard from '../../../components/EssayCard/EssayCard';
import { getEssaysAvailableForReservation } from '../../../services/EssaysData';
import HeaderContainer from '../../../components/Outputs/HeaderContainer';
import CardsLayout from '../../../layouts/CardsLayout';
import { updateEssay } from '../../../services/EssaysData';
import { getCurrentUser } from '../../../services/UsersData';
import { clearTable, doesTableExist, pushGradingTable } from '../../../services/GradingTablesData';
import { Essay } from '../../../assets/types';

export default function ReserveEssay() {

    const [essays, setEssays] = useState<Essay[]>([] as Essay[]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        getEssaysAvailableForReservation().then((items)=>{
            if(items) 
            {
                setEssays(items)
                setLoading(false);
            }
        });
    }, []);

    function handleChoose (eid: number) {
        getCurrentUser().then((user)=>{
            if(user) {
                const teacher_id = user.id;
                updateEssay(eid, {teacher_id: teacher_id, status: 4, started_grading_time: new Date(Date.now()).toISOString()}).then(()=>{
                    
                    doesTableExist(eid.toString()).then((exists)=>{
                        //console.log(exists);
                        if(exists === true){
                            clearTable(eid.toString()).then(()=>{
                                navigate("/teacher/grade");
                            })
                        } else if (exists === false) {
                            pushGradingTable(eid.toString()).then(()=>{
                                navigate("/teacher/grade");
                            })
                        }
                    })
                })
            }
        })
    }

    if (loading)
    {
        return <Loading/>;
    }

    return (
        <>
            <HeaderContainer>
                Zacznij od wybrania pracy do sprawdzenia.
            </HeaderContainer>
            <CardsLayout>
                {essays?.map((essay, i)=>(
                    <EssayCard 
                        key={i}
                        exam={essay.product.exam} 
                        generation={essay.product.generation} 
                        id={essay.id} 
                        subject={essay.product.subject} 
                        level={essay.product.level} 
                        action={()=>handleChoose(essay.id)}
                        words={essay.content.split(" ").length}
                        type={essay.task.subtype.name} 
                        status={3}
                        cardFunction={"choose"}
                        actionText={"Zarezerwuj"}
                    />
                ))}
            </CardsLayout> 
        </>
    )
}
import { GradingTable } from "../assets/types";
import { gradingTablesHeaders } from "../data/constants";
import { supabase } from "./client";
import { getEssayProduct } from "./EssaysData";

export async function getGradingTablesById (essay_id: string) {
    try {
        const { data, error } = await supabase
            .from('gradingTables')
            .select('*')
            .eq('essay_id', essay_id)
            .single()
        if (error) throw error;
        if (data) {
            let table: GradingTable = {} as GradingTable;
            table.essay_id = Number(essay_id);
            table.columns = [];

            let downloadedTable = [] as  number[];
            downloadedTable.push(data.A ?? 0, data.B ?? 0, data.C ?? 0, data.D ?? 0, data.E ?? 0, data.F ?? 0, data.G ?? 0, data.H ?? 0);
            return getEssayProduct(essay_id).then((product)=>{
                if(product !== undefined) {
                    const productHeaders = gradingTablesHeaders.find((element)=>(element.product_id === product.id));
                    if (productHeaders)
                    {
                        const numberOfHeaders = productHeaders.headers.length;
                         for( let i = 0; i < numberOfHeaders; i++ ){
                            const tableCol = {
                                header: productHeaders.headers[i],
                                points: downloadedTable[i],
                            };
                            table.columns.push(tableCol);
                        }
                        return table as GradingTable;
                    }
                }
            })
        }
    } catch (error) {
        console.error(error)
    }
}

export async function pushGradingTable (essay_id: string) {
    try {
        const {error, status} = await supabase
            .from('gradingTables')
            .insert({ essay_id, A: 0, B: 0, C: 0, D: 0, E: 0, F: 0, G: 0, H: 0 })
            .select("*")

        if (error && status !== 406) throw error;
    }
    catch (error)
    {
        console.error(error)
    }
}

export async function doesTableExist (essay_id: string) {
    try {
        const {error, count, status} = await supabase
            .from('gradingTables')
            .select("*", { count: 'exact', head: true })
            .match({essay_id: essay_id})

        if (error && status !== 406) throw error;
        if (count === 0 || count === undefined || count === null) return false;
        else if(count && count > 0) return true;
    }
    catch (error)
    {
        console.error(error)
    }
}

export async function clearTable (essay_id: string) {
    try {
        const {error, status} = await supabase
            .from('gradingTables')
            .update({ essay_id, A: 0, B: 0, C: 0, D: 0, E: 0, F: 0, G: 0, H: 0 })
            .eq("essay_id", essay_id)

        if (error && status !== 406) throw error;
    }
    catch (error)
    {
        console.error(error)
    }
}

  /**
   * Note: @param columnNumber needs to be betwen 0 and 7 
   */
export async function updateGradingTable (essay_id: string, index: number, value: number)
{
    try {
        const {error, status} = await supabase
            .from('gradingTables')
            .update({[String.fromCharCode(index+65)]: value})
            .eq("essay_id", essay_id)

        if (error && status !== 406) throw error;
    }
    catch (error)
    {
        console.error(error)
    }
}
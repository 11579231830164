import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Subtype, Task } from '../../../assets/types';
import BigInput from '../../../components/Inputs/BigInput';
import CheckInput from '../../../components/Inputs/CheckInput';
import Loading from '../../../layouts/Loading';
import SelectInput from '../../../components/Inputs/SelectInput';
import SmallInput from '../../../components/Inputs/SmallInput';
import { doesProductRequireFragment } from '../../../services/EssaysData';
import { getSubtypes, getTask, updateTask } from '../../../services/TasksData';

type EditTaskProps = {
    
};

const EditTask = ( props: EditTaskProps) => {
    
    const {id} = useParams();
    const [task, setTask] = useState({} as Task);
    const [loading, setLoading] = useState(true);
    const [subtypes, setSubtypes] = useState([] as Subtype[])
    const [fragment, setFragment] = useState(false);

    useEffect(()=>{
        if(id) {
            getTask(Number(id)).then((result)=>{
                if (result)
                {
                    setTask(result);
                    doesProductRequireFragment(result.product_id.toString()).then((isRequired)=>{
                        if(isRequired !== undefined && isRequired !== null) {
                            setFragment(isRequired);
                            getSubtypes().then((sub)=>{
                                if(sub) {
                                    setSubtypes(sub);
                                    setLoading(false);
                                }
                            })
                        }
                    })
                }
            })
        }
    },[id])

    if (!id) {
        return <Navigate to={'/error'}/>;
    }    
    if (loading ) return <Loading />;
    else return (
        <div className='w-full mt-8'>
            <div className='mx-auto w-80 md:w-[800px] p-4 mb-3 flex flex-col gap-2 rounded-xl bg-neutral-200 dark:bg-neutral-700 border border-neutral-300 dark:border-neutral-600 font-medium text-sm dark:text-neutral-200 text-neutral-800'>
                <BigInput id={id} label={"Polecenie"} description={"Zapisz całe polecenie."} valueName={"task"} firstText={task.task} save={updateTask} instantSaving={false}/>
                <SelectInput id={id} label={"Typ pracy"} valueName={"subtype_id"} firstValue={task.subtype_id ? task.subtype_id : subtypes[0].id} save={updateTask} options={subtypes} />
                <CheckInput id={id} label={"Widoczność"}description={"Czy to polecenie może być udostępnione innym użytkownikom?"} valueName={"is_public"} firstValue={task.is_public} save={updateTask} />
                <CheckInput id={id} label={"Zrobione przez admina"}description={""} valueName={"admin_made"} firstValue={task.admin_made} save={updateTask} />
                { fragment ? <><div className='flex flex-row w-full gap-4'>
                    <div className='w-1/2'><SmallInput id={id} label={"Autor"} valueName={"fragment_author"} firstText={task.fragment_author} save={updateTask} instantSaving={false} /></div>
                    <div className='w-1/2'><SmallInput id={id} label={"Tytuł"} valueName={"fragment_title"} firstText={task.fragment_title} save={updateTask} instantSaving={false} /></div>
                </div>
                <SmallInput id={id} label={"Źródło fragmentu"} description={"Jest ono zazwyczaj podane na dole ostatniej strony egzaminu z danym fragmentem."} valueName={"fragment_source"} firstText={task.fragment_source} save={updateTask} instantSaving={false} />
                </>: ""}
                <SmallInput id={id} label={"Źródło tego polecenia"} description={"Podaj źródło tego polecenia np. Matura CKE maj 2022."} valueName={"task_source"} firstText={task.task_source} save={updateTask} instantSaving={false} />
                { fragment ? <BigInput id={id} label={"Fragment"} description={"Przepisz fragment tekstu kultury. Pamiętaj o formatowaniu, zwałaszcza w przypadku wierszy."} valueName={"fragment_content"} firstText={task.fragment_content} save={updateTask} instantSaving={false} />
                : "" }
            </div>
        </div>
    );
};

export default EditTask;
import { Transition } from '@headlessui/react';
import React, { useEffect, useState } from 'react'
import { examColors, fallbackBgColor, fallbackColor } from '../../data/constants';

type Props = {
    exam: string;
    subject: string;
    level: string;
    generation: string;
    type: string;
    eid: number;
    action: (eid: number) => void;
}

export default function WriteEssayTicket(props: Props) {

    const headerstyle = {
        "fontWeight": 900,
        "font-size":"6px",
        "opacity":"90%",
        //"text-transform":"uppercase",    
    }
    
    const textStyle = {
        "fontWeight": 700,
        "text-transform":"uppercase",
        "font-size":"5px",
        "opacity":"80%",
    }
    
    const yearStyle = {
        "fontWeight": 900,
        "text-transform":"uppercase",
        "font-size":"12px",
        "opacity":"40%",
    }
    
    const numStyle = {
        "fontWeight": 900,
        "text-transform":"uppercase",
        "font-size":"6px",
        "opacity":"90%",
    }

    const bgColor = examColors.find(element => element.exam === props.exam && element.generation === props.generation)?.main ?? fallbackBgColor;
    const color = examColors.find(element => element.exam === props.exam && element.generation === props.generation)?.dark ?? fallbackColor;

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
    }, [])

    return (
        <Transition
            show={loading}
            enter="transition-opacity duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
        >
            <button className='w-80 h-[120px] md:w-60 md:h-[90px] m-auto ease-in duration-150 hover:scale-105' onClick={()=>{props.action(props.eid)}}>
                <svg /* width="240px" */ className='w-80 md:w-60' version="1.1" viewBox="0 0 80 30" xmlns="http://www.w3.org/2000/svg">
                    <path 
                        transform="scale(.26458)" 
                        fill={bgColor} 
                        d="m20.586 0c-11.404 0-20.586 9.1818-20.586 20.586v9.5723l0.0078125 0.0039a9.4488 9.4488 0 0 0 6.2773 8.7012c0.00406 0.0015 0.00564 0.0044 0.00977 0.0059 7.3423 2.5931 12.604 9.5941 12.604 17.824-4.97e-4 8.1277-5.1402 15.043-12.34 17.711-3.9062 1.255-6.5561 4.8874-6.5586 8.9902 0.0016231 0.04559 0.0035762 0.09116 0.0058594 0.13672l-0.0058594 0.007812v9.2617c0 11.404 9.1818 20.586 20.586 20.586h29.672v-5.6699a3.7795 3.7795 0 0 1 3.7793-3.7793 3.7795 3.7795 0 0 1 3.7793 3.7793v5.6699h223.96c11.404 0 20.584-9.1818 20.584-20.586v-9.5488h-2e-3a9.4488 9.4488 0 0 0-6.3867-8.7969c-7.2862-2.6227-12.507-9.5722-12.508-17.762-3.5e-4 -8.1962 5.2167-15.174 12.512-17.793 2e-3 -7.05e-4 2e-3 -0.0032 4e-3 -0.0039a9.4488 9.4488 0 0 0 6.3789-8.8848l2e-3 -2e-3v-9.4238c-9e-5 -11.404-9.18-20.586-20.584-20.586h-223.96v5.668a3.7795 3.7795 0 0 1-3.7793 3.7793 3.7795 3.7795 0 0 1-3.7793-3.7793v-5.668zm33.451 13.229a3.7795 3.7795 0 0 1 3.7793 3.7793v11.338a3.7795 3.7795 0 0 1-3.7793 3.7793 3.7795 3.7795 0 0 1-3.7793-3.7793v-11.338a3.7795 3.7795 0 0 1 3.7793-3.7793zm0 22.678a3.7795 3.7795 0 0 1 3.7793 3.7793v11.338a3.7795 3.7795 0 0 1-3.7793 3.7793 3.7795 3.7795 0 0 1-3.7793-3.7793v-11.338a3.7795 3.7795 0 0 1 3.7793-3.7793zm0 22.676a3.7795 3.7795 0 0 1 3.7793 3.7793v11.338a3.7795 3.7795 0 0 1-3.7793 3.7793 3.7795 3.7795 0 0 1-3.7793-3.7793v-11.338a3.7795 3.7795 0 0 1 3.7793-3.7793zm0 22.678a3.7795 3.7795 0 0 1 3.7793 3.7793v11.338a3.7795 3.7795 0 0 1-3.7793 3.7793 3.7795 3.7795 0 0 1-3.7793-3.7793v-11.338a3.7795 3.7795 0 0 1 3.7793-3.7793z" 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth="9.507"
                    />
                    <text x="18" y="7" style={headerstyle} fill={color} >{props.exam} - {props.subject}</text>
                    <text x="18" y="13" style={textStyle} fill={color}>{props.level}</text>
                    <text x="18" y="18" style={textStyle} fill={color}>{props.type}</text>
                    <text x="11" y="27" transform='rotate(-90, 11, 27)' style={numStyle} fill={color}>#{props.eid}</text>
                    <text x="45" y="30" style={yearStyle} fill={color}>{props.generation}</text>
                </svg>
            </button>
        </Transition>
    )
}

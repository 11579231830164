import React from 'react'

export default function Loading() {
    return (
        <div className='grid grid-cols-1 content-center w-full h-screen m-auto text-3xl font-bold bg-neutral-50 text-neutral-800 dark:bg-neutral-800 dark:text-neutral-50'>
            <div className='flex justify-center w-full h-fit'>
                Ładowanie...
            </div>
        </div>
    )
}

import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Loading from '../../../layouts/Loading';
import EssayCard from '../../../components/EssayCard/EssayCard';
import { getProductsAll } from '../../../services/EssaysData';
import CardsLayout from '../../../layouts/CardsLayout';
import HeaderContainer from '../../../components/Outputs/HeaderContainer';

type Props = {}

export default function Tasks(props: Props) {
    const [products, setProducts] = useState<any[]>();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        getProductsAll().then((data)=>{
            if(data) {
                data.sort(function (a, b) {
                    return Number(a.generation) - Number(b.generation);
                });
                setProducts(data)
                setLoading(false);
            }
        });
    }, []);

    function handleChoose (id: number) {
        navigate(`/admin/tasks/${id}`);
    }

    if (loading)
    {
        return <Loading/>;
    }

    return (
        <>
            <div className='flex flex-col w-min justify-center gap-4 m-auto h-full pt-4 bg-neutral-50 dark:bg-neutral-800 text-neutral-800 dark:text-neutral-50'>
                <HeaderContainer>
                    Wybierz produkt, do którego chcesz dodać fragment
                </HeaderContainer>
                {/* <SemiHeaderContainer>
                    Produkty
                </SemiHeaderContainer> */}
                <CardsLayout>
                    {products?.map((product, i)=>(
                        <EssayCard 
                            key={i}
                            exam={product.exam} 
                            id={product.id}
                            generation={product.generation} 
                            subject={product.subject} 
                            level={product.level} 
                            action={()=>handleChoose(product.id)}
                            writingTime={product.max_time}
                            minWords={product.min_words}
                            type={product.type} 
                            price={product.price}
                            cardFunction={"modify"}
                            actionText={"Dodaj fragment"}
                        />
                    ))}
                </CardsLayout>
            </div>
        </>
    )
}
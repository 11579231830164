import React, { useState, useEffect } from 'react';

type GreetingsProps = {
  name: string;
};

const Greetings: React.FC<GreetingsProps> = ({ name }) => {
    const date = new Date();
    const [greeting, setGreeting] = useState<string>('');

    function getGreeting() {
            const hour = date.getHours();
            return hour > 18 || hour < 5 ? 'Dobry wieczór' : 'Dzień dobry';
    }

    useEffect(() => {
        const updateGreeting = () => {
            setGreeting(getGreeting());
        };
        setGreeting(getGreeting());
        const intervalId = setInterval(updateGreeting, 900000); // 900 000 ms = 15 min

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className='w-full h-fit text-3xl font-semibold text-left break-words overflow-clip'>
        {greeting}, <br className='block md:hidden' />
        <span className='text-essayGreenLight font-extrabold'>{name}</span>
        </div>
    );
};

export default Greetings;

import React from 'react'
import { ringGraphDefaultOptions } from '../../data/constants'

type Props = {}

export default function RingGrapshDesc(props: Props) {
  return (
    <div className='h-full w-full text-center flex flex-col justify-center'>
      <div>
        <div className='text-xl md:text-2xl font-bold m-auto text-left'>
          Średnie
        </div>
        <div className='text-base md:text-xl font-semibold m-auto text-left'>
          Wynik: <span className={`text-2xl md:text-4xl font-extrabold`} style={{color: ringGraphDefaultOptions[0].main}}>65</span>%
        </div>
        <div className='text-base md:text-xl font-semibold m-auto text-left'>
          Czas: <span className={`text-2xl md:text-4xl font-extrabold`} style={{color: ringGraphDefaultOptions[1].main}}>100</span>min
        </div>
        <div className='text-base md:text-xl font-semibold m-auto text-left'>
          Słowa: <span className={`text-2xl md:text-4xl font-extrabold`} style={{color: ringGraphDefaultOptions[2].main}}>345</span>
        </div>
      </div>
    </div>
  )
}
import React, { useEffect } from 'react'
import { ArcData, ArcGraphOptions, PartialArcGraphOptions } from '../../assets/types';
import { mergeDeep } from '../../data/globalFunctions';
import { useDarkMode } from '../../hooks/useDarkMode';

type Props = {
    canvasId: string,
    data: ArcData,
    options?: Partial<PartialArcGraphOptions> | null
}

export default function ArcGraph(props: Props) {

    const isDarkMode = useDarkMode();

    const defaultOptions = {
        colors: {
            light: {
                bg: "#bfbfbf",
                line: "#008f50",
                markedReached: "#008f50",
                mainText: "black",
                text: "black"
            },
            dark: {
                bg: "lightgray",
                line: "#008f50",
                markedReached: "#008f50",
                mainText: "white",
                text: "white"
            }
        },
        dimensions: {
            lineWidth: 0.15,
            radius: 0.425,
            cutout: 0.25
        },
        valueText: {
            fontSize: 0.15,
            font: "Helvetica"
        },
        minMaxText: {
            fontSize: 0.1,
            font: "Helvetica"
        },
        labelText: {
            fontSize: 0.1,
            font: "Helvetica"
        },
        displayMinMax: true
    };
    const options = props.options ? mergeDeep(defaultOptions, props.options) : defaultOptions;
    const  data = props.data;

    function showData( options: ArcGraphOptions, data: ArcData )
    {
        if(props.data.value >= props.data.maxValue) props.data.value = props.data.maxValue;

        const cutout = options.dimensions.cutout;
        const arcStart = (cutout === 0 ? 0 : cutout === 1 ? 0 : ((cutout)+0.5))*Math.PI;
        const arcEnd = (cutout === 0 ? 2 : cutout === 1 ? 0 : (0.5-(cutout)))*Math.PI;

        const canvas = document.getElementById(props.canvasId) as HTMLCanvasElement;
        const val = canvas.getContext("2d") as CanvasRenderingContext2D;
        const bg = canvas.getContext("2d") as CanvasRenderingContext2D;
        const text = canvas.getContext("2d") as CanvasRenderingContext2D;

        const w = canvas.width;
        const h = canvas.height;
        const graphCenter = { x: w/2, y: h/2 }

        let isMarkedReached: boolean = false;
        if(data.markedValue) isMarkedReached = ( data.value >= data.markedValue );

        val.clearRect(0, 0, w, h);
        text.clearRect(0, 0, w, h);

        bg.strokeStyle = isDarkMode ? options.colors.dark.bg : options.colors.light.bg;
        bg.lineWidth = options.dimensions.lineWidth * h;
        bg.lineCap = 'round';
        bg.beginPath();
        bg.arc(graphCenter.x, graphCenter.y, options.dimensions.radius * h, arcStart, arcEnd);
        bg.stroke();

        const arcValue = data.value <= data.maxValue ? ((((data.value - data.minValue)/(data.maxValue - data.minValue))*(1-options.dimensions.cutout)*2*Math.PI) + arcStart) : arcEnd;
        //let value = data.value <= 999 ? data.value : 999;

        /// GRADIENT TEST - PASSED
        /* var gradient = val.createConicGradient(arcStart+Math.PI/2-0.35,graphCenter.x, graphCenter.y);
        gradient.addColorStop(0, "blue");
        gradient.addColorStop(0.25, "violet");
        gradient.addColorStop(0.5, "yellow");
        gradient.addColorStop(0.75, "orange");
        gradient.addColorStop(1, "red");
        val.strokeStyle = gradient; */
        
        val.strokeStyle = isDarkMode ? options.colors.dark.line : options.colors.light.line;
        val.lineWidth = options.dimensions.lineWidth * w;
        val.lineCap = 'round';
        val.beginPath();
        val.arc(graphCenter.x, graphCenter.y, options.dimensions.radius*h , arcStart, arcValue);
        val.stroke();

        if(data.markedValue) {

            const arcMarked = ((((data.markedValue - data.minValue)/(data.maxValue - data.minValue))*(1-options.dimensions.cutout)*2*Math.PI) + arcStart);
            const markedPosX = graphCenter.x + options.dimensions.radius*h*Math.cos(arcMarked);
            const markedPosY = graphCenter.y + options.dimensions.radius*h*Math.sin(arcMarked);
            
            if(isMarkedReached)
            {
                val.strokeStyle = isDarkMode ? options.colors.dark.markedReached : options.colors.light.markedReached;
                val.lineWidth = options.dimensions.lineWidth*h/2
                val.lineCap = 'round';
                val.beginPath();
                val.arc(markedPosX, markedPosY, options.dimensions.lineWidth*h/4 , 0, 2*Math.PI);
                val.stroke();
            }

            val.globalCompositeOperation = "destination-out";

            val.lineWidth = 30
            val.lineCap = 'round';
            val.beginPath();
            val.arc(markedPosX, markedPosY, (options.dimensions.lineWidth/2)*h , 0, 2*Math.PI);
            val.stroke();

            val.globalCompositeOperation = "source-over";
        }

        text.textAlign = "center"
        text.textBaseline = "middle"

        text.fillStyle = isDarkMode ? options.colors.dark.mainText : options.colors.light.mainText;
        text.font =  "bold " + (options.valueText.fontSize*h).toString() + "px " + options.valueText.font;
        text.fillText(data.displayedValue, graphCenter.x, graphCenter.y);

        const lPosX = graphCenter.x + options.dimensions.radius*h*1.1*Math.cos(arcStart-0.35);
        const lPosY = graphCenter.y + options.dimensions.radius*h*1.1*Math.sin(arcStart-0.35);

        const rPosX = graphCenter.x + options.dimensions.radius*h*1.1*Math.cos(arcEnd+0.35);
        const rPosY = graphCenter.y + options.dimensions.radius*h*1.1*Math.sin(arcEnd+0.35);

        text.fillStyle = isDarkMode ? options.colors.dark.text : options.colors.light.text;
        if(data.displayedMinValue && data.displayedMaxValue) {
            text.font = "bold " + (options.minMaxText.fontSize*h).toString() + "px " + options.minMaxText;
            text.fillText(data.displayedMinValue, lPosX, lPosY);
            text.fillText(data.displayedMaxValue, rPosX, rPosY);
        }

        text.font = "bold " + (options.labelText.fontSize*h).toString() + "px " + options.labelText.font;
        if(data.labelText) { text.fillText(data.labelText.toUpperCase() , graphCenter.x, graphCenter.y*1.77) };
    }

    useEffect(()=> {
        showData( options, data )
    }, [isDarkMode, data])

    return (
        <canvas id={props.canvasId} className='w-full h-full'/* className='w-32 md:w-40 h-32 md:h-40' */ width="1000" height="1000" />
    )
}
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon, InformationCircleIcon } from '@heroicons/react/24/outline'
import { Tooltip } from 'antd';
import React from 'react'
import { FiInfo } from 'react-icons/fi';

type Props = {
    header: string;
    content: string;
    source: string;
}

export default function FragmentDisplay({header, content, source}: Props) {
    return (
        <div className=/*"mx-auto */" w-full rounded-2xl bg-neutral-200 dark:bg-neutral-700 border border-neutral-300 dark:border-neutral-600 p-2">
            <Disclosure>
            {({ open }) => (
                <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium hover:bg-neutral-200 hover:dark:bg-neutral-700 dark:bg-neutral-600 bg-neutral-100 text-neutral-800 dark:text-neutral-200 border-neutral-300 dark:border-neutral-500 border-2">
                    <span>{header}</span>
                    <ChevronUpIcon
                    className={`${
                        open ? 'rotate-180 transform duration-150 ease-in' : 'rotate-0 transform duration-150 ease-in'
                    } h-5 w-5`}
                    />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-neutral-800 dark:text-neutral-50 text-justify whitespace-pre-wrap">
                    <div className='w-full'>
                        <div className=' whitespace-pre-wrap '>
                            {content}
                        </div>
                        <div  className={'w-full flex flex-row'}>
                            <div className='w-full'></div>
                            <Tooltip title={source} showArrow={false}>
                                <FiInfo size={20} />
                            </Tooltip>
                        </div>
                    </div>
                </Disclosure.Panel>
                </>
            )}
            </Disclosure>
      </div>
    )
}
import { User } from '@supabase/supabase-js';
import { UserData } from '../assets/types';
import { supabase } from './client';

export async function isLogged() {
    try {
        const { data, error } = await supabase.auth.getUser();
        if(error) throw error
        if (data?.user) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error(error)
    }
}

export async function getCurrentUser() {
    try {
        const { data: { session }, error } = await supabase.auth.getSession();
        if ( error ) throw error
        if (!session?.user) {
            throw new Error('User not logged in')
        }
        return session?.user as User
    } catch (error) {
        console.log(error)
    }
}

export async function getCurrentUserData() {
    try {
        const user = await getCurrentUser()
        let { data, error, status } = await supabase
        .from('users')
        .select()
        .eq('id', user?.id)
        .single()
        
        if (error && status !== 406) throw error
        if (data) {
            return data as UserData;
        }
    } catch (error) {
        console.error(error)
    }
}

export async function getCurrentUserRole() {
    try {
        const user = await getCurrentUser()
        let { data, error, status } = await supabase
            .from('users')
            .select('role')
            .eq('id', user?.id)
            .single()
            
        if (error && status !== 406) throw error
        if (data) {
            return data.role as string;
        }
    } catch (error) {
        console.error(error)
    }
}

export async function doesCurrentUserDataExist() {
    try {
        const user = await getCurrentUser()
        let { count, error, status } = await supabase
            .from('users')
            .select("*", {head: false, count: "exact"})
            .eq('id', user?.id)
        
        if (error && status !== 406) throw error
        if ( count && count === 1 ) {
            return true;
        }
        else if ( count === 0 || count === undefined || count === null )
        {
            return false;
        }
    } catch (error) {
        console.error(error)
    }
}

export async function pushUserData() {
    try {
        const user = await getCurrentUser()
        if(user) {
            let { error, status } = await supabase
                .from('users')
                .insert({id: user.id, name: "", surname: "", email: user.email, role: "student"})
            
            if (error && status !== 406) throw error
        }
    } catch (error) {
        console.error(error)
    }
}

// TEST -> DO NOT IMPLEMENT THOUGHTLESSLY
export async function updateUserData(data: Partial<UserData>) {
    try {
        const user = await getCurrentUser()
        let { error, status } = await supabase
            .from('users')
            .update(data)
            .eq('id', user?.id)
        
        if (error && status !== 406) throw error
    } catch (error) {
        console.error(error)
    }
}

import React, { useState } from 'react'
import { useNavigate } from "react-router-dom"
import { useAuth } from '../../context/AuthContext'

const ResetPassword = () => {

    const [ newPassword, setNewPassword ] = useState("");
    const [ confirmNewPassword, setConfirmNewPassword ] = useState("");
    const [ loading, setLoading ] = useState(false);
    const navigate = useNavigate();
    const { updatePassword } = useAuth();

    const handleReset = async (event: any) => {
        event.preventDefault();
        setLoading(true);
        try {
            if ( newPassword !== confirmNewPassword ) throw new Error("Hasło i jego powtórzenie są różne!");
            const { error } = await updatePassword(newPassword);
            if (error) throw error;
            navigate("/signin");
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
            <div className="w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-xl">
                <h1 className="text-3xl font-semibold text-center text-purple-700 underline">
                   Ustaw nowe hasło
                </h1>
                <form onSubmit={handleReset} className="mt-6">
                    <div className="mb-2">
                        <input
                            type="password"
                            placeholder='Nowe hasło'
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                    </div>
                    <div className="mb-2">
                        <input
                            type="password"
                            placeholder='Powtórz nowe hasło'
                            value={confirmNewPassword}
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                            className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                    </div>
                    <div className="mt-6">
                        <button disabled={loading} className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-purple-700 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600">
                            USTAW
                        </button>
                    </div>
                </form>

                <p className="mt-8 text-xs font-light text-center text-gray-700">
                    {" "}
                    Nie masz jeszcze konta?{" "}
                    <a
                        href="/signup"
                        className="font-medium text-purple-600 hover:underline"
                    >
                        Zarejestruj się!
                    </a>
                </p>
            </div>
        </div>
    )
}

export default ResetPassword;
import React from 'react'

type Props = {
    id: number;
}

export default function IdDisplay(props: Props) {
  return (
    <div className='text-xl font-extrabold'>#{props.id}</div>
  )
}

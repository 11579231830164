import { Transition } from '@headlessui/react';
import React, { useEffect, useState } from 'react'

type Props = {
    children: any;
}

export default function HeaderContainer(props: Props) {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
    }, [])
    

    return (
        <Transition
            show={loading}
            enter="transition-opacity duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
        >
            <div className='w-80 md:w-[600px] text-2xl font-semibold'>
                {props.children}
            </div>
        </Transition>
    )
}
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Loading from '../../../layouts/Loading';
import EssayCard from '../../../components/EssayCard/EssayCard';
import { getEssaysCheckedByCurrentUser } from '../../../services/EssaysData';
import CardsLayout from '../../../layouts/CardsLayout';
import HeaderContainer from '../../../components/Outputs/HeaderContainer';
import { Essay } from '../../../assets/types';

export default function ViewCheckedEssays() {

    const [essays, setEssays] = useState<Essay[]>([] as Essay[]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        getEssaysCheckedByCurrentUser().then((essays)=>{
            if(essays) setEssays(essays)
            setLoading(false);
        });
    }, []);

    function handleChoose (eid: number) {
        navigate(`/teacher/view/${eid}`);
    }

    if (loading)
    {
        return <Loading/>;
    }

    return (

        <>
            <HeaderContainer>
                Tutaj znajdują się sprawdzone przez Ciebie prace. Możesz je przejrzeć ponownie lub zglościć niezgodności.
            </HeaderContainer>
            <CardsLayout>
                {essays?.map((essay, i)=>(
                    <EssayCard 
                        key={i}
                        exam={essay.product.exam} 
                        generation={essay.product.generation} 
                        id={essay.id} 
                        subject={essay.product.subject} 
                        level={essay.product.level} 
                        action={()=>handleChoose(essay.id)}
                        type={essay.task.subtype.name} 
                        status={3}
                        cardFunction={"view"}
                        actionText={"Zobacz"}
                    />  
                ))}
            </CardsLayout>
        </>
    )
}

import { useEffect, useState } from "react";
import { Highlighter } from "./TestGrading";
import { highlightType } from "../assets/types";
import Annotation from "../components/EssayView/MagicAnnotations/Annotation";

type props = {
    side: string;
    highlighter: Highlighter | null;
}

export default function AnnotationManager( { side, highlighter }: props ) {

    const [highlights, setHighlights] = useState([] as highlightType[]);

    function handleReprintAnnotations () {
        highlighter?.sortHighlights()
        const left: highlightType[] = [], right: highlightType[] = [];
        highlighter?.highlights.forEach((hl, i)=>{ if (i%2) { right.push(hl); } else { left.push(hl); } });
        setHighlights(side === "left" ? left : right)
    }

    useEffect(() => {
        window.addEventListener("reprint_annotations", handleReprintAnnotations);
        return () => window.removeEventListener("reprint_annotations", handleReprintAnnotations);
        // eslint-disable-next-line
    }, []);

    if (!highlighter) return <></>;
    else return (
        <div className="flex-col">
            {
                highlights.map((highlight, i) => {
                    return (
                        <Annotation key={i} highlight={highlight} highlighter={highlighter} isEditable={true}/>
                    )
                })
            }
        </div>
    )
}

import { highlightType } from "../assets/types";
import { supabase } from "./client";

export async function pushHighlight(essay_id: string, teacher_id: string, highlight: highlightType) {
    try {
        const { error, status } = await supabase
            .from('highlights')
            .insert({
                highlight_id: highlight.id, 
                essay_id: essay_id,
                teacher_id: teacher_id,
                start: highlight.start,
                end: highlight.end,
                type: highlight.type,
                selected_text: highlight.selected_text,
                comment: highlight.comment,
                color: highlight.color,
                color_on_focus: highlight.color_on_focus,
            })
            .select(`
            highlight_id,
            selected_text
            `)
            .limit(1)

        if (error && status !== 406) throw error;
    }
    catch (error)
    {
        console.error(error)
    }
}

export async function updateHighlightComment(highlightId: number, essayId: number, comment: string) {
    try {
        const { error, status } = await supabase
            .from('highlights')
            .update({
                comment: comment
            })
            .eq("highlight_id", highlightId)
            .eq("essay_id", essayId)
            .select(`
            comment
            `)
        if (error && status !== 406) throw error;
    }
    catch (error)
    {
        console.error(error)
    }
}

export async function deleteHighlight(highlight_id: number, essay_id: number) {
    try {
        const { error, status } = await supabase
            .from('highlights')
            .delete()
            .match({
                "highlight_id": highlight_id,
                "essay_id": essay_id
            })

        if (error && status !== 406) throw error;

    }
    catch (error) {
        console.error(error)
    }
}

export async function getHighlights (essayId: number){
    const highlights: highlightType[] = [] as highlightType[];

    try {
        const { error, status, data, count } = await supabase
            .from('highlights')
            .select(`*`, { count: 'exact', head: false })
            .match({
                "essay_id": essayId
            })

        if (error && status !== 406) throw error;
        else if(data) {
            if(count) {
                data.forEach((highlight) => 
                {
                    highlights.push(
                        {
                            id: highlight.highlight_id, 
                            start: highlight.start,
                            end: highlight.end,
                            type: highlight.type,
                            selected_text: highlight.selected_text,
                            comment: highlight.comment,
                            position: { top: 0, left: 0, width: 0 },
                            color: highlight.color,
                            color_on_focus: highlight.color_on_focus,
                        }
                    )
                })
            }
        }
    }
    catch (error)
    {
        console.error(error)
    }

    return highlights;
}

/* TO DO */
export async function deleteAllHighlightsForEssay(essayId: number) {
    /* try {
        const { error, status } = await supabase
            .from('highlights')
            .delete()
            .eq("essayId", essayId)
    
        if (error && status !== 406) throw error;
    }
    catch (error)
    {
        console.error(error)
    } */
}
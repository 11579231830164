import React, { useEffect, useState } from 'react'
import { statusColors } from '../../data/constants';
import { useInterval } from '../../hooks/useInterval';
import StatusBar from './StatusBar';

type Props = {
    chosenOn: number,
    deadline: number
}

function formatTime(milliseconds: number) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
    const seconds = totalSeconds - (hours * 3600) - (minutes * 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

export default function GradeRemainingTime(props: Props) {

    const [remainingTime, setRemainingTime] = useState(Date.now())
    const [time, setTime] = useState("");
    const [color, setColor] = useState("")
    const [loading, setLoading] = useState(true);
    let now = Date.now();

    useEffect(()=>{
        now = Date.now();
        setRemainingTime(((props.deadline-now)/(props.deadline-props.chosenOn))*100)
        remainingTime > statusColors.ok.threshold ? setColor(statusColors.ok.color) : (remainingTime > statusColors.warning.threshold ? setColor(statusColors.warning.color) : setColor(statusColors.bad.color));
        setTime(formatTime(props.deadline-now));
        setLoading(false);
        // eslint-disable-next-line 
    },[])

    useInterval(() => {
        now = Date.now();
        setRemainingTime(((props.deadline-now)/(props.deadline-props.chosenOn))*100)
        remainingTime > statusColors.ok.threshold ? setColor(statusColors.ok.color) : (remainingTime > statusColors.warning.threshold ? setColor(statusColors.warning.color) : setColor(statusColors.bad.color));
        setTime(formatTime(props.deadline-now));
    }, 1000);

    useEffect(()=>{

    }, [remainingTime])

    if ( loading ) return <></>;
    else if (remainingTime > 0) { 
        return (
            <div className='my-2'>
                <div className=' text-md '>
                    Pozostały czas: <span className='font-semibold'>{time}</span>
                </div>
                <div className='mt-1'>
                    <StatusBar percentValue={remainingTime}/>
                </div>
            </div>
    )}
    else return <>Przekroczono czas</>;
}

import React from 'react'

type Props = {
    percentValue: number;
}

export default function StatusBar({percentValue}: Props) {
    return (
        <div className='w-full h-3 rounded-xl bg-neutral-50 dark:bg-neutral-200'>
            <div style={{width: percentValue.toString() + "%"}} className=" bg-emerald-500 h-full rounded-xl"></div>
        </div>
    )
}
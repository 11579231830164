import { useState, useEffect } from 'react';

export const useDarkMode = (): boolean => {
    const [isDarkMode, setIsDarkMode] = useState<boolean>(
      window.matchMedia('(prefers-color-scheme: dark)').matches,
    );
  
    useEffect(() => {
      const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      const handleChange = () => setIsDarkMode(darkModeMediaQuery.matches);
  
      darkModeMediaQuery.addEventListener('change', handleChange);
  
      return () => {
        darkModeMediaQuery.removeEventListener('change', handleChange);
      };
    }, []);
  
    return isDarkMode;
};
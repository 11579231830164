import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Task } from '../../../assets/types';
import ChooseTask from '../../../layouts/ChooseTask';
import HeaderContainer from '../../../components/Outputs/HeaderContainer'
import Loading from '../../../layouts/Loading';
import SemiHeaderContainer from '../../../components/Outputs/SemiHeaderContainer';
import { updateEssay } from '../../../services/EssaysData';
import { getEssayProduct, getEssayStatus } from '../../../services/EssaysData';
import { pushTaskForProduct } from '../../../services/TasksData';
import { getCurrentUser } from '../../../services/UsersData';

export default function BeforeYouProceedStudent() {

    const { id } = useParams();
    const navigate = useNavigate();
    const [chosenTask, setChosenTask] = useState(0);
    const [chosenSubtype, setChosenSubtype] = useState(0);
    const [loading, setLoading] = useState(true);
    const [newTask, setNewTask] = useState({} as Task);
    const [useOwn, setUseOwn] = useState(false);

    useEffect(()=>{
        if( id ) {
            getEssayStatus(id).then((status)=>{
                if(status) {
                    if(status >= 2)
                    {
                        navigate("/student");
                    }
                    else {
                        setLoading(false);
                    }
                }
            })
        }
    })

    function handleContinue () {
        getEssayProduct(id ?? "").then((product)=>{
            if(product) {
                const requiresFragment = product.is_fragment_required;
                getCurrentUser().then((user)=>{
                    if(user) {
                        let copy = newTask;
                        copy = Object.assign(copy, {"owner_id": user.id, "product_id": product.id })

                        if( useOwn && newTask.subtype_id )
                        {
                            pushTaskForProduct(copy).then((newTaskId)=>{
                                updateEssay(Number(id), {"status": 2, "task_id" : newTaskId }).then(()=>{
                                    navigate("/student/write");
                                    navigate("/student/write/"+id);
                                })
                            })
                        }
                        else if(!useOwn && chosenSubtype!==-1)
                        {
                            updateEssay(Number(id), {"status": 2, "task_id" : chosenTask }).then(()=>{
                                navigate("/student/write");
                                navigate("/student/write/"+id);
                            })
                        }
                        else{
                            alert("Uzupełnij wszystkie pola poprawnie!");
                        }
                    }
            })}
        })
    }

    if (!id) return <Navigate to="/error"/>
    if (loading) return <Loading />
    else {
    return (
        <div className='w-full h-full'>
            <div className='flex w-80 md:w-[600px] flex-col justify-center m-auto h-full pt-4 bg-neutral-50 dark:bg-neutral-800 text-neutral-800 dark:text-neutral-50'>
                <HeaderContainer>
                    Zanim zaczniesz, oto parę ważnych informacji
                </HeaderContainer>
                <SemiHeaderContainer>
                    1. Istotna informacja numer 1
                </SemiHeaderContainer>
                <ChooseTask essayId={Number(id)} chosenTask={chosenTask} setChosenTask={setChosenTask} chosenSubtype={chosenSubtype} setChosenSubtype={setChosenSubtype} useOwn={useOwn} setUseOwn={setUseOwn} newTask={newTask} setNewTask={setNewTask}/>
                <div className='flex flex-row justify-end pt-3 pb-3'>
                    <button className="bg-blue-500 hover:bg-blue-700 text-white h-fit font-bold py-2 px-4 rounded" 
                        onClick={handleContinue}>
                            Kontynuuj
                    </button>
                </div>
            </div>
        </div>
    )}
}
import React, {useState, useRef, useEffect, useCallback} from 'react'
import Paper from '../components/EssayView/Paper';
import { DebouncedFunc } from 'lodash';
import debounce from 'lodash.debounce';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { getEssayForCurrentUser, getEssayStatus, updateEssay, updateEssayContent } from '../services/EssaysData';
import Loading from './Loading';
import EssayDashboard from '../components/EssayDashboard/EssayDashboard';
import { Essay } from '../assets/types';
import { useWindowSize } from '../hooks/useWindowsSize';
import UserPageLayout from './UserPageLayout';

const areaStyle = {
    tabSize: "40px"
}

export default function WritingContainer() {

    const { id } = useParams();
    const eid = parseInt(id as string);
    const [loading, setLoading] = useState(true);
    const [text, setText] = useState("");
    const [isSaved, setIsSaved] = useState(false);
    const [status, setStatus] = useState(0);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [unavailable, setUnavailable] = useState(false);
    const navigate = useNavigate();
    const [essay, setEssay] = useState({} as Essay);
    const size = useWindowSize();
    const [hMatched, setHMatched] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(event.target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === "Tab") {
        event.preventDefault();
        const start = event.currentTarget.selectionStart;
        const end = event.currentTarget.selectionEnd;
        const newText = text.substring(0, start) + "\t" + text.substring(end);
        setText(newText);
        setTimeout(() => {
            if (textAreaRef && textAreaRef.current) {
            textAreaRef.current.selectionStart = start + 1;
            textAreaRef.current.selectionEnd = start + 1;
            }
        }, 0);
        }
        /* if (event.key === "Enter") {
        event.preventDefault();
        const start = event.currentTarget.selectionStart;
        const end = event.currentTarget.selectionEnd;
        const newText = text.substring(0, start) + "\n" + text.substring(end);
        setText(newText);
        setTimeout(() => {
            if (textAreaRef && textAreaRef.current) {
            textAreaRef.current.selectionStart = start + 1;
            textAreaRef.current.selectionEnd = start + 1;
            }
        }, 0);
        } */
    };

    // FIX MAY BE NEEDED
    // eslint-disable-next-line
    const debouncedSave: DebouncedFunc<(text: string) => Promise<void>> = useCallback(
        debounce(async (text: string) => {
            if (status === 1)
            {
                await updateEssay(eid, {content: text, status: 2});
            }
            else
            {
                await updateEssayContent(eid, text);
            }
          setIsSaved(true)
          
    }, 1500), []);
    
    /// DOES NOT SHIFT THE STATUS CORRECTLY
    useEffect(() => {
        getEssayForCurrentUser(id ?? "").then((essay)=>{
            if(!essay) {setUnavailable(true);}
            else {
                setText(essay.content as string);
                setStatus(essay.status as number);
                setEssay(essay);
                if(essay.status > 2)
                {
                    navigate("/student");
                }
                else {
                    setLoading(false);
                }
            }
        })
        // eslint-disable-next-line
    }, [id])
    
    useEffect(() => {
        setIsSaved(false)
        debouncedSave(text);
    }, [text, debouncedSave])

    useEffect(() => {
        const handleSave = (event: KeyboardEvent) => {
          if ((event.ctrlKey|| event.metaKey) && event.key === 's') {
            event.preventDefault();
            if (status === 1)
            {
                updateEssay(eid, {content: text, status: 2});
            }
            else
            {
                updateEssayContent(eid, text);
            }
            setIsSaved(true)
          }
        };
    
        document.addEventListener('keydown', handleSave);
    
        return () => {
          document.removeEventListener('keydown', handleSave);
        };
    });

    useEffect(()=>{
        if (textAreaRef.current) {
            if(textAreaRef.current.scrollHeight !== undefined && textAreaRef.current.scrollHeight > 900)
            {
                textAreaRef.current.style.height = "";
                textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
            }
            else {
                textAreaRef.current.style.height = "900px"
            }
        }
    },[text, (size.width > 768)])

    useEffect(()=>{
        if (textAreaRef.current) {
            if(textAreaRef.current.scrollHeight !== undefined && textAreaRef.current.scrollHeight > 900)
            {
                textAreaRef.current.style.height = "";
                textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
            }
            else {
                textAreaRef.current.style.height = "900px"
            }
        }
    },[])

    function sendEssayForGrading() {

        if (text !== "" && status !== 1)
        {
            updateEssay(eid, {content: text, status: 3, sent_time: new Date(Date.now()).toISOString()}).then(()=>{
                navigate('/student/view');
            })
        } else if (text === "") {
            alert("Twoja praca jest pusta!");
        }
        setIsSaved(true);
    }
    
    if (!id || unavailable) {
        return <Navigate to={'/error'}/>;
    }
    if(status === 1) {
        if( id ) {
            getEssayStatus(id).then((status)=>{
                if(status) {
                    if(status < 3)
                    {
                        navigate(`/student/write/before-you-proceed/${id}`);
                    }
                }
            })
        }
    }
    if (loading)
    {
        return <Loading/>;
    }
    return (
        <UserPageLayout>
            <EssayDashboard essay={essay} content={text} role={"write"} points={6} />
            <Paper eid={eid} isSaved={isSaved}>
                <textarea
                    ref={textAreaRef}
                    style={areaStyle}
                    value={text}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    placeholder={"Aa"}
                    className={"text-justify w-full h-[900px] rounded-lg bg-transparent resize-none focus:outline-none"}
                ></textarea>
            </Paper>
            <div className='flex flex-row justify-end py-3 gap-3'>
                <button className="bg-blue-500 hover:bg-blue-700 text-white h-fit font-bold py-2 px-4 rounded-lg" 
                    onClick={()=>{
                        if (text !== "" && status !== 1)
                        {
                            updateEssay(eid, {content: text});
                        }
                        setIsSaved(true);
                    }}>
                        Zapisz
                </button>
                <button className="bg-blue-500 hover:bg-blue-700 text-white h-fit font-bold py-2 px-4 rounded-lg" 
                    onClick={()=>{
                        sendEssayForGrading();
                    }}>
                        Wyślij do sprawdzenia
                </button>
            </div>
        </UserPageLayout>
    )
}

import React, { useEffect, useState } from 'react'
import { Task } from '../../assets/types';

type Props = {
    id: string;
    label: string;
    description?: string;
    valueName: string;
    firstValue: boolean;
    save(id: number, updatedData: Partial<Task>): Promise<void> | void
}

export default function CheckInput({id, label, valueName, firstValue, description, save}: Props) {

    const [value, setValue] = useState(firstValue ? firstValue : false);

    function handleChange (event: React.ChangeEvent<HTMLInputElement>) {
        setValue(event.target.checked);
        save(Number(id), {[valueName]: event.target.checked});
    }

    useEffect(()=>{
        save(Number(id), {[valueName]: firstValue ? firstValue : false})
    },[id])

    return (
        <div className='flex flex-col gap-2'>
            <div className='text-neutral-700 dark:text-neutral-200 font-semibold text-xl'>
                {label}
            </div>
            { description ? (
            <div className='text-neutral-700 dark:text-neutral-200 font-normal text-md'>
                {description}
            </div>
            ) : ("")}

            <label className="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" className="sr-only peer" checked={value} onChange={handleChange} />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
        </div>
    )
}
import { Transition } from '@headlessui/react';
import React, { useEffect, useState } from 'react'
import { examColors } from '../../data/constants';
import CardHeader from './CardHeader';
import CardParameter from './CardParameter';
import CardStatus from './CardStatus';
import GradeRemainingTime from './GradeRemainingTime';

type Props = {
    exam: string,
    generation: string,
    subject: string,
    level: string,
    id: number,
    type: string,
    cardFunction: string,
    writingTime?: number,
    words?: number,
    minWords?: number,
    status?: number,
    result?: number,
    sentOn?: number,
    chosenOn?: number,
    deadline?: number,
    actionText: string,
    price?: number;
    reducedPrice?: number;
    action: (eid: number, props?: {}) => void,
}

function numberToStringWithComma(number: number) {
    let stringNumber = number.toString();
    stringNumber = stringNumber.replace('.', ',');
    return stringNumber;
}

export default function EssayCard(props: Props) {

    const bgColor = examColors.find(element => element.exam === props.exam && element.generation === props.generation)?.main;
    const color = examColors.find(element => element.exam === props.exam && element.generation === props.generation)?.dark;

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
    }, [])

    return (
        <Transition
            show={loading}
            enter="transition-opacity duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
        >
            <div className='w-80 md:w-60 bg-neutral-200 dark:bg-neutral-700 border border-neutral-300 dark:border-neutral-600 block rounded-2xl m-auto p-4'>
                <CardHeader bgColor={bgColor} color={color} exam={props.exam} generation={props.generation}/>
                
                { props.cardFunction !== "buy"? 
                <div className=' text-xl mt-2'>
                    {"#"}<span className='font-semibold'>{props.id}</span>
                </div> : ""}
                
                <CardParameter label="Przedmiot" value={props.subject} />
                <CardParameter label="Poziom" value={props.level} />
                <CardParameter label="Typ" value={props.type} />

            {/* CONDITIONAL PROMPTS */}

                { props.cardFunction === "write" || props.cardFunction === "waiting" || props.cardFunction === "ready" || props.cardFunction === "view" || props.cardFunction === "buy"? 
                    <CardParameter label="Czas" value={props.writingTime??""} unit="min" />
                : ""}
                { props.cardFunction === "buy"? 
                    <CardParameter label="Minimum słów" value={props.minWords??""} />
                : ""}

                {
                    props.cardFunction === "buy" && props.reducedPrice ? (
                        <div className='flex flex-row pt-2 '>
                            <button 
                                onClick={()=>{props.action(props.id)}}
                                className={`w-fit text-xl md:text-base bg-essayGreen hover:bg-essayGreenDark duration-150 hover:duration-150 ease-in-out text-white font-bold py-[6px] px-3 rounded-lg my-auto `}
                            >
                                {props.actionText}
                            </button>
                            <div className='w-full h-fit px-2 text-right'>
                                <span className={`font-semibold ${props.reducedPrice ? "text-base md:text-sm text-neutral-500 dark:text-neutral-300 line-through" : " text-3xl md:text-2xl"}`}>{numberToStringWithComma(props.price??0)}zł</span>
                                <br></br>
                                <span className='font-semibold text-3xl md:text-2xl'>{numberToStringWithComma(props.reducedPrice??0)}</span>zł
                            </div>
                        </div>
                    ) : ""
                }

{
                    props.cardFunction === "buy" && !props.reducedPrice ? (
                        <div className='flex flex-row pt-2 '>
                            <button 
                                onClick={()=>{props.action(props.id)}}
                                className={`w-fit text-xl md:text-base bg-main bg-essayGreen hover:bg-essayGreenDark duration-150 hover:duration-150 ease-in-out text-white font-bold py-[6px] px-3 rounded-lg my-auto `}
                            >
                                {props.actionText}
                            </button>
                            <div className='w-full h-fit px-2 text-right my-auto'>
                                <span className={`font-semibold ${props.reducedPrice ? "text-base md:text-sm text-neutral-500 dark:text-neutral-300 line-through" : " text-3xl md:text-2xl"}`}>{numberToStringWithComma(props.price??0)}zł</span>
                            </div>
                        </div>
                    ) : ""
                }
                { props.cardFunction === "write" || props.cardFunction === "waiting" || props.cardFunction === "ready" || props.cardFunction === "view" ? 
                    <CardParameter label="Słowa" value={props.words??""} denominator={props.minWords} colorValue={true} />
                : ""}
                { props.cardFunction === "waiting" ? 
                    <CardParameter label="Wysłano" value={props.sentOn??""} />
                 : ""}
                { props.cardFunction === "waiting" || props.cardFunction === "ready"? 
                    <CardStatus status={props.status??0}/>
                : ""}
                { props.cardFunction === "view" ? 
                    <CardParameter label="Wynik" value={props.result??""} unit={"%"} />
                : ""}
                { props.cardFunction === "choose" || props.cardFunction === "grade" ? 
                    <CardParameter label="Słowa" value={props.words??""}/>
                 : ""}
                { ( props.cardFunction === "choose" || props.cardFunction === "grade" ) && props.words ?
                    <CardParameter label="Czas czytania" value={Math.ceil(props.words/225)} unit={"min"} />
                : ""}
                { props.cardFunction === "grade" && props.deadline && props.chosenOn ? 
                    <GradeRemainingTime deadline={props.deadline} chosenOn={props.chosenOn}/> 
                : ""}

                { props.cardFunction !== "buy" ? <div className='w-full text-right'><button 
                    onClick={()=>{props.action(props.id)}}
                    className={`bg-essayGreen hover:bg-essayGreenDark duration-150 hover:duration-150 ease-in-out text-white font-bold py-[6px] px-3 rounded-lg mt-2 my-auto`}
                >
                    {props.actionText}
                </button>
                </div> : ""}
            </div>
        </Transition>
    )
}
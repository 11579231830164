import React, { useEffect, useRef, useState } from 'react';
import { Highlighter } from './TestGrading';
import { useAuth } from '../context/AuthContext';
import PopUp from '../components/EssayView/MagicAnnotations/PopUp';

type GradingPaperProps = {
    highlighter: Highlighter | null;
    showPopup: boolean;
    setShowPopup: (showPopup: boolean) => void;
    showAnnotations: boolean;
    setShowAnnotations: (showAnnotations: boolean) => void
};

export default function GradingPaper({ highlighter, showPopup, setShowPopup, showAnnotations, setShowAnnotations }: GradingPaperProps) {
    const { user } = useAuth();
    const originRef = useRef<HTMLDivElement | null>(null);
    const [textareaHeight, setTextareaHeight] = useState<number>(0);


    function clickAction() {
        if (!highlighter || !user || !(user.id === highlighter.teacher_id)) { return; }
        if (showPopup) {
            highlighter.handlePopup("Delete");
            setShowPopup(false);
        }

        const textarea = document.getElementById("selectable") as HTMLTextAreaElement;
        if (!textarea || textarea.selectionStart === textarea.selectionEnd) { 
            window.getSelection()?.removeAllRanges();
            return; 
        }

        highlighter.handleRequest(textarea);
        setShowPopup(true);
        window.dispatchEvent(new Event("reposition_popup"));
    }

    useEffect(() => {
        highlighter?.setupTextarea();
    
        // Calculate and set the textareaHeight after the textarea is set up or if the content changes.
        const textarea = document.getElementById("selectable") as HTMLTextAreaElement;
        if (textarea) {
            const computedStyle = window.getComputedStyle(textarea);
            const lineHeight = parseFloat(computedStyle.lineHeight);
            const paddingTop = parseFloat(computedStyle.paddingTop);
            const paddingBottom = parseFloat(computedStyle.paddingBottom);
            const contentHeight = textarea.scrollHeight - paddingTop - paddingBottom;
            const numberOfLines = Math.ceil(contentHeight / lineHeight);
            const newHeight = numberOfLines * lineHeight;
            setTextareaHeight(newHeight);
        }
    }, [highlighter, highlighter?.text]);
    
    useEffect(() => {
    // Set the height of the origin div to match the calculated textarea height.
        if (originRef.current) {
            originRef.current.style.height = `${textareaHeight}px`;
        }
    }, [textareaHeight]);

    return (
        <div id="origin" className='relative w-[350px] md:w-[700px]'       ref={originRef} // Attach a ref to the origin div
        >
            {showPopup && highlighter && highlighter.tempHighlight && highlighter.tempHighlight.position && (
                <PopUp
                    id={highlighter?.tempHighlight?.id || ''}
                    top={highlighter.tempHighlight.position.top}
                    left={highlighter.tempHighlight.position.left}
                    width={highlighter.tempHighlight.position.width}
                    product_id={highlighter?.product_id || ''}
                    highlighter={highlighter}
                    setShowPopup={setShowPopup}
                    setShowAnnotations={setShowAnnotations}
                />
            )}
            <div
                id="wrapper-template"
                style={{
                    height: "fit", whiteSpace: "pre-wrap", tabSize: "40px",
                    color: "transparent", position: "absolute", top: "0", left: "0",
                    textAlign: "justify", borderRadius: "0.5rem", backgroundColor: "transparent",
                    resize: "none", userSelect: "none"
                }}
                className='w-[350px] md:w-[700px]'
            ></div>
            <textarea
                id="selectable"
                onMouseUp={clickAction}
                onTouchEnd={clickAction}
                className={"selection:bg-red-500 absolute top-0 left-0 text-justify w-[350px] md:w-[700px] rounded-lg bg-transparent resize-none focus:outline-none"}
                style={{ tabSize: "40px" }}
                value={highlighter?.text}
                readOnly
            />
        </div>
    );
}

import React, { useEffect, useRef, useState } from 'react'
import { updateEssay } from '../../services/EssaysData';
import ArcGraph from '../Graphs/ArcGraph';

type Props = {
    essay_id: number;
    minWords: number;
    ptsThreshold: number;
    words: number;
    maxWords: number | null;
    maxTime: number;
    time: number;
    role: string;
    maxPoints?: number;
    points: number | null;
    setTime: React.Dispatch<React.SetStateAction<number>>;
}

export default function EssayDashboardGraphs({essay_id, minWords, ptsThreshold, maxTime, maxWords, words, time, role, setTime, points, maxPoints}: Props) {

    const [isRunning, setIsRunning] = useState<boolean>(false);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const [timeString, setTimeString] = useState<string>("");

    const start = () => {
        setIsRunning(true);
        intervalRef.current = setInterval(() => {
            setTime((prevTime) => prevTime + 1);
        }, 1000);
    };

    const stop = () => {
        setIsRunning(false);
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
    };

    const reset = () => {
        setTime(0);
        setIsRunning(false);
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
    };

    const formatTime = (time: number): string => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;

        return `${hours < 10 ? '0' + hours : hours}:${
            minutes < 10 ? '0' + minutes : minutes
            }:${seconds < 10 ? '0' + seconds : seconds}`;
    };

    useEffect(()=>{
        setTimeString(formatTime(time))
        updateEssay(essay_id, {writing_time: time})
    }, [time])

    const timeGraphOptions = {
        colors: {
            light: {
                line: "#f49d0b",
                markedReached: "#f49d0b",
            },
            dark: {
                line: "#f49d0b",
                markedReached: "#f49d0b",
            }
        }
    }

    const wordsGraphOptions = {
        colors: {
            light: {
                line: "#185abd",
                markedReached: "#185abd",
            },
            dark: {
                line: "#185abd",
                markedReached: "#185abd",
            }
        },
        valueText: {
            fontSize: 0.2,
            font: "Helvetica"
        },
    }

    const okPointsGraphOptions = {
        colors: {
            light: {
                line: "#008f50",
                markedReached: "#008f50",
            },
            dark: {
                line: "#008f50",
                markedReached: "#008f50",
            }
        },
        valueText: {
            fontSize: 0.3,
            font: "Helvetica"
        },
    }

    const badPointsGraphOptions = {
        colors: {
            light: {
                line: "#b30000",
                markedReached: "#b30000",
            },
            dark: {
                line: "#b30000",
                markedReached: "#b30000",
            }
        },
        valueText: {
            fontSize: 0.3,
            font: "Helvetica"
        },
    }

    return (
        <div className='flex flex-col gap-2 w-[350px] md:w-[700px] text-neutral-800 dark:text-neutral-50'>
            <div className='w-full flex flex-row gap-2'>
                {
                    role === "grade" && (points!==null || points!==undefined) && maxPoints ? (
                        <div className='w-full rounded-2xl bg-neutral-200 dark:bg-neutral-700 border border-neutral-300 dark:border-neutral-600 p-2'>
                            <ArcGraph 
                                options={ points && points > ptsThreshold ? okPointsGraphOptions : (points ? badPointsGraphOptions : null) } 
                                canvasId={"points"} 
                                data={{displayedValue: (points??0).toString(), markedValue: ptsThreshold !== 0 ? ptsThreshold : null, minValue: 0, maxValue: maxPoints, value: points??0, labelText: "punkty"  }}/>
                        </div>
                    ) : ""
                }
                <div className='w-full rounded-2xl bg-neutral-200 dark:bg-neutral-700 border border-neutral-300 dark:border-neutral-600 p-2'>
                    <ArcGraph 
                        options={wordsGraphOptions} 
                        canvasId={"words"} 
                        data={{minValue: 0, maxValue: maxWords ? maxWords : 999, markedValue: minWords, value: words, displayedValue: words.toString(), labelText: "słowa" }}/>
                </div>
                <div className='w-full rounded-2xl bg-neutral-200 dark:bg-neutral-700 border border-neutral-300 dark:border-neutral-600 p-2'>
                    <ArcGraph 
                        options={timeGraphOptions} 
                        canvasId={"time"} 
                        data={{displayedValue: timeString, markedValue: null, minValue: 0, maxValue: maxTime*60, value: time, labelText: "czas"  }}/>
                </div>
            </div>
            { role === "write" ? (<div className='w-full flex flex-row gap-2 font-bold'>
                <button onClick={start} disabled={isRunning} className='px-3 py-3 w-full rounded-2xl bg-neutral-200 dark:bg-neutral-700 border border-neutral-300 dark:border-neutral-600'>
                    START
                </button>
                <button onClick={stop} disabled={!isRunning} className='px-3 py-3 w-full rounded-2xl bg-neutral-200 dark:bg-neutral-700 border border-neutral-300 dark:border-neutral-600'>
                    STOP
                </button>
                <button onClick={reset} className='px-3 py-3 w-full rounded-2xl bg-neutral-200 dark:bg-neutral-700 border border-neutral-300 dark:border-neutral-600'>
                    RESET
                </button>
            </div> ) : ""}
        </div>
    )
}
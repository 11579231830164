import { useEffect } from 'react';
import { RingColorType, RingGraphValues } from '../../assets/types';
import { ringGraphDefaultOptions } from '../../data/constants';
import { useDarkMode } from '../../hooks/useDarkMode';

type Props = {
    values: RingGraphValues;
}

export default function RingGraph({values}: Props) {

    const isDarkMode = useDarkMode();

    const options: RingColorType[] = ringGraphDefaultOptions;

    function showData( values: RingGraphValues ){
        const c = document.getElementById("RingGraph") as HTMLCanvasElement;
        const ctx = c.getContext("2d") as CanvasRenderingContext2D;
        const bg = c.getContext("2d") as CanvasRenderingContext2D;
        const ctx2 = c.getContext("2d") as CanvasRenderingContext2D;
        const ctx3 = c.getContext("2d") as CanvasRenderingContext2D;
        
        bg.clearRect(0, 0, c.width, c.height);
        ctx.clearRect(0, 0, c.width, c.height);
        ctx2.clearRect(0, 0, c.width, c.height);
        ctx3.clearRect(0, 0, c.width, c.height);
  
        let mainColor = options[0].main;
        let bgColorLight = options[0].light;
        let bgColorDark = options[0].dark;
        let shadowLight = 'white';
        let shadowDark = 'black';

        let bgColor = '';
        let shadow = '';

        let darkMode = isDarkMode;
  
        shadow = darkMode ? shadowDark : shadowLight;
        bgColor = darkMode ? bgColorDark : bgColorLight;

        let value = values[0];
  
        const start = 1.5*Math.PI;
        const full = 2*Math.PI;
        let end = 1.5*Math.PI + (value/100)*full;
        const lineW = c.width/10;
        const shadowRadius = 10;
        let plotRadius = c.width*(135/300);

        bg.beginPath();
        bg.strokeStyle = bgColor;
        bg.lineWidth = lineW;
        bg.lineCap = 'round';
        bg.arc(c.width / 2, c.height / 2, plotRadius,start,start+full);
        bg.stroke();

        ctx.beginPath();
        ctx.strokeStyle = mainColor;
        ctx.lineWidth = lineW;
        ctx.lineCap = 'round';
        ctx.arc(c.width / 2, c.height / 2, plotRadius,start,end);
        ctx.stroke();

        ctx2.beginPath();
        ctx2.strokeStyle = mainColor;
        ctx2.lineWidth = lineW;
        ctx2.shadowColor = shadow;
        ctx2.lineCap = 'round';
        ctx2.shadowOffsetX = -Math.sin(end) * shadowRadius;
        ctx2.shadowOffsetY = Math.cos(end) * shadowRadius;
        ctx2.shadowBlur = c.width/20;
        ctx2.arc(c.width / 2, c.height / 2, plotRadius,end,end+.00001);
        ctx2.stroke();

        ctx3.beginPath();
        ctx3.strokeStyle = mainColor;
        ctx3.lineWidth = lineW;
        ctx3.shadowOffsetX = 0;
        ctx3.shadowOffsetY = 0;
        ctx3.shadowBlur = 0;
        ctx3.lineCap = 'round';
        if(value >= 50 && value < 100)
        {
            ctx3.arc(c.width / 2, c.height / 2, plotRadius,start+full/2,end);
        }
        else if(value < 50)
        {
            ctx3.arc(c.width / 2, c.height / 2, plotRadius,start,end);
        }
        else if (value === 100)
        {
            ctx3.arc(c.width / 2, c.height / 2, plotRadius,start-full/2,start);

        }
        else if (value > 100)
        {
            ctx3.arc(c.width / 2, c.height / 2, plotRadius,end%full-Math.PI,end%full);
        }
        ctx3.stroke();

        ///////////////////

        plotRadius = c.width*(100/300)
        value = values[1];
        end = 1.5*Math.PI + (value/100)*full;
        mainColor = options[1].main;
        bgColorLight = options[1].light;
        bgColorDark = options[1].dark;

        bgColor = darkMode ? bgColorDark : bgColorLight;

        bg.beginPath();
        bg.strokeStyle = bgColor;
        bg.lineWidth = lineW;
        bg.lineCap = 'round';
        bg.arc(c.width / 2, c.height / 2, plotRadius,start,start+full);
        bg.stroke();

        ctx.beginPath();
        ctx.strokeStyle = mainColor;
        ctx.lineWidth = lineW;
        ctx.lineCap = 'round';
        ctx.arc(c.width / 2, c.height / 2, plotRadius,start,end);
        ctx.stroke();

        ctx2.beginPath();
        ctx2.strokeStyle = mainColor;
        ctx2.lineWidth = lineW;
        ctx2.shadowColor = shadow;
        ctx2.lineCap = 'round';
        ctx2.shadowOffsetX = -Math.sin(end) * shadowRadius;
        ctx2.shadowOffsetY = Math.cos(end) * shadowRadius;
        ctx2.shadowBlur = c.width/50;
        ctx2.arc(c.width / 2, c.height / 2, plotRadius,end,end+.00001);
        ctx2.stroke();

        ctx3.beginPath();
        ctx3.strokeStyle = mainColor;
        ctx3.lineWidth = lineW;
        ctx3.shadowOffsetX = 0;
        ctx3.shadowOffsetY = 0;
        ctx3.shadowBlur = 0;
        ctx3.lineCap = 'round';
        if(value >= 50 && value < 100)
        {
            ctx3.arc(c.width / 2, c.height / 2, plotRadius,start+full/2,end);
        }
        else if(value < 50)
        {
            ctx3.arc(c.width / 2, c.height / 2, plotRadius,start,end);
        }
        else if (value === 100)
        {
            ctx3.arc(c.width / 2, c.height / 2, plotRadius,start-full/2,start);

        }
        else if (value > 100)
        {
            ctx3.arc(c.width / 2, c.height / 2, plotRadius,end%full-Math.PI,end%full);
        }
        ctx3.stroke();

        ////////////

        plotRadius = c.width*(65/300)
        value = values[2];
        end = 1.5*Math.PI + (value/100)*full;
        mainColor = options[2].main;
        bgColorLight = options[2].light;
        bgColorDark = options[2].dark;

        bgColor = darkMode ? bgColorDark : bgColorLight;

        bg.beginPath();
        bg.strokeStyle = bgColor;
        bg.lineWidth = lineW;
        bg.lineCap = 'round';
        bg.arc(c.width / 2, c.height / 2, plotRadius,start,start+full);
        bg.stroke();

        ctx.beginPath();
        ctx.strokeStyle = mainColor;
        ctx.lineWidth = lineW;
        ctx.lineCap = 'round';
        ctx.arc(c.width / 2, c.height / 2, plotRadius,start,end);
        ctx.stroke();

        ctx2.beginPath();
        ctx2.strokeStyle = mainColor;
        ctx2.lineWidth = lineW;
        ctx2.shadowColor = shadow;
        ctx2.lineCap = 'round';
        ctx2.shadowOffsetX = -Math.sin(end) * shadowRadius;
        ctx2.shadowOffsetY = Math.cos(end) * shadowRadius;
        ctx2.shadowBlur = c.width/50;
        ctx2.arc(c.width / 2, c.height / 2, plotRadius,end,end+.00001);
        ctx2.stroke();

        ctx3.beginPath();
        ctx3.strokeStyle = mainColor;
        ctx3.lineWidth = lineW;
        ctx3.shadowOffsetX = 0;
        ctx3.shadowOffsetY = 0;
        ctx3.shadowBlur = 0;
        ctx3.lineCap = 'round';
        if(value >= 50 && value < 100)
        {
            ctx3.arc(c.width / 2, c.height / 2, plotRadius,start+full/2,end);
        }
        else if(value < 50)
        {
            ctx3.arc(c.width / 2, c.height / 2, plotRadius,start,end);
        }
        else if (value === 100)
        {
            ctx3.arc(c.width / 2, c.height / 2, plotRadius,start-full/2,start);

        }
        else if (value > 100)
        {
            ctx3.arc(c.width / 2, c.height / 2, plotRadius,end%full-Math.PI,end%full);
        }
        ctx3.stroke();

        // DEFECTS SUBTRACTION
        ctx.globalCompositeOperation = "destination-in"
        plotRadius = c.width*(100/300)
        ctx.beginPath();
        ctx.lineWidth = lineW;
        ctx.lineCap = 'round';
        ctx.arc(c.width / 2, c.height / 2, plotRadius,start,start+full);
        plotRadius = c.width*(135/300)
        ctx.moveTo((c.width/2),(c.height/2)-plotRadius)
        ctx.lineWidth = lineW;
        ctx.lineCap = 'round';
        ctx.arc(c.width / 2, c.height / 2, plotRadius,start,start+full);
        plotRadius = c.width*(65/300)
        ctx.moveTo((c.width/2),(c.height/2)-plotRadius)
        ctx.lineWidth = lineW;
        ctx.lineCap = 'round';
        ctx.arc(c.width / 2, c.height / 2, plotRadius,start,start+full);
        ctx.stroke();
        ctx.globalCompositeOperation = "source-over"
    }

    useEffect(()=> {
        showData(values)
    }, [isDarkMode])
        
    return (
        <canvas id="RingGraph" className='w-32 md:w-40 h-32 md:h-40' width="1000" height="1000"></canvas>
    )
}

import React, { useRef, useEffect, useState, ChangeEvent } from 'react'
import { maxQuoteLength, midSeparator } from '../../../data/constants';
import { useWindowSize } from '../../../hooks/useWindowsSize';
import { Highlighter } from '../../../layouts/TestGrading';
import { highlightType } from '../../../assets/types';

type Props = {
    highlight: highlightType;
    highlighter: Highlighter | null;
    isEditable: boolean;
}

export default function Annotation({ highlight, highlighter, isEditable }: Props ) {

    const quote = highlight.selected_text;
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [isSaved, setIsSaved] = useState(true);
    const [text, setText] = useState(highlight.comment);
    const size = useWindowSize();

    function printQuote() {
        if (quote.length <= maxQuoteLength*5)  { return quote; }

        const words = quote.split(' ');
        const firstPart = words.slice(0, maxQuoteLength/2).join(' ');
        const lastPart = words.slice(-maxQuoteLength/2).join(' ').trim();

        const result = firstPart + midSeparator + lastPart;
        if (result.length - midSeparator.length >= quote.length) { return quote; }
        return result;
    }
    function handleDelete() {
        highlighter?.remove(highlight.id);
    }

    function handleSave() {
        highlighter?.updateComment(highlight.id, textareaRef.current?.value as string);
        setIsSaved(true);
    }

    function handleChange(e: ChangeEvent<HTMLTextAreaElement>) {
        matchHeightRef(textareaRef);
        setText(textareaRef.current?.value as string);
        setIsSaved(false);
    }

    function matchHeightRef(ref?: React.RefObject<HTMLTextAreaElement>) {
        if (textareaRef.current) {
            textareaRef.current.style.height = "";
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight+4}px`;
        }
    }

    useEffect(()=>{
        matchHeightRef(textareaRef);
    },[size])

    /* useEffect(() => {
        const handleSave = (event: KeyboardEvent) => {
          if ((event.ctrlKey || event.metaKey) && event.key === 's') {
            event.preventDefault();
            props.immediateSave(textareaRef.current?.value as string, (props.essayId), Number(props.hlId), setIsSaved)
            setIsSaved(true)
          }
        };
    
        document.addEventListener('keydown', handleSave);
    
        return () => {
          document.removeEventListener('keydown', handleSave);
        };
    }); */

    return (
        
        <div id={"A"+highlight.id} className='w-72 md:w-60 p-3 mb-3 mx-3 rounded-xl bg-neutral-200 dark:bg-neutral-700 border border-neutral-300 dark:border-neutral-600 font-medium text-sm text-neutral-200 dark:text-neutral-800'>
            <div id="error" className=' shadow-lg select-none float-right w-10 h-10 text-2xl text-white pt-1 rounded-lg text-center ml-1 mb-1' style={{backgroundColor: highlight.color}}>
                    {highlight.type}
            </div>
            <div id="quote" className="text-xs dark:text-neutral-200 text-neutral-800 before:content-[open-quote] after:content-[close-quote] pt-1 pl-1 pb-2">
                <b>{
                printQuote()
                }</b>
            </div>
            {isEditable ? (<textarea 
                id={"comment-"+highlight.id} 
                className='text-left w-full rounded-lg resize-none dark:bg-neutral-600 bg-neutral-100 text-neutral-800 dark:text-neutral-200 border-neutral-300 dark:border-neutral-500 border-2 p-1'
                ref={textareaRef}
                rows={1}
                onInput={handleChange}
                onChange={handleChange}
                value={text}
                onBlur={()=>{ 
                    if (highlighter) { highlighter.lastHighlighyId = null; }
                    //highlighter?.unfocusHighlight(highlight.id);
                    handleSave(); 
                }}
                onFocus={()=>{
                    //highlighter?.focusHighlight(highlight.id);
                }}
            />) : 
            <textarea 
                id={"comment-"+highlight.id} 
                ref={textareaRef}
                className='text-left w-full rounded-lg resize-none bg-transparent text-neutral-800 dark:text-neutral-200 border-neutral-300 dark:border-neutral-500 border-2 p-1'
                value={text}
                readOnly disabled
            />
            }
            {isEditable ? (<button type="button" onClick={()=>{handleDelete()}} className=" text-red-800 dark:text-red-500 rounded-lg text-xs font-medium px-2 py-1" >
                Usuń
            </button>) : ""}
            { isEditable ? ( isSaved ? <span className=' text-neutral-400 text-xs '>Zapisano</span> : <button type='button' onClick={()=>{handleSave()}}><span className=' text-neutral-700 dark:text-neutral-200 text-xs'>Zapisz</span> </button> ) : ""}
        </div>
    )
}
import React from 'react'
import { IconType } from 'react-icons/lib'
import { useNavigate } from 'react-router-dom';

type Props = {
    icon: IconType;
    link: string;
}

export default function SidebarMobileButton({icon, link}: Props) {

    const navigate = useNavigate();

    return (
        <div className='flex flex-row py-5 duration-150 hover:duration-150 ease-in-out hover:dark:text-essayGreenLight hover:text-essayGreen w-full h-full' onClick={()=>{navigate(link);}}>
            <div className='m-auto'>{React.createElement(icon, { size: "32" })}</div>
        </div>
    )
}
import React, { ReactNode } from 'react';

interface UserPageLayoutProps {
  children: ReactNode;
}

const UserPageLayout: React.FC<UserPageLayoutProps> = ({ children }) => {

    return (
        <div className='pb-28 md:pb-0 pl-0 md:pl-16 w-full'>
            <div className="flex flex-col items-center justify-between min-h-screen py-14 bg-neutral-50 dark:bg-neutral-800 text-neutral-800 dark:text-neutral-50">
                <div className='grid gap-4'>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default UserPageLayout;

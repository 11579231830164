import React from 'react'

type Props = {
    saved: boolean
}

export default function SavingStateDisplay({saved}: Props) {
    return (
        <div className='w-full text-right'>
            { saved ? (
                <span className=' text-neutral-400 text-xs '>
                    Zapisano
                </span> 
            ) : (
                <span className=' text-neutral-700 dark:text-neutral-200 text-xs '>
                    Zapisywanie
                </span>
            )}
        </div>
    )
}
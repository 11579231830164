import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Task } from "../../../assets/types";
import { doesProductRequireFragment } from "../../../services/EssaysData";
import { deleteTask, getTasksForProduct, pushTaskForProduct } from "../../../services/TasksData";
import TaskList from "../../../components/Tasks/TaskList";
import Loading from "../../../layouts/Loading";
import { getCurrentUser } from "../../../services/UsersData";

type Props = {}

export default function EssayTasks(props: Props) {

    const {id} = useParams();
    const [tasks, setTasks] = useState([] as Task[]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [fragment, setFragment] = useState(true);

    function editTask (tid: number) {
        navigate(`/admin/tasks/edit/${tid}`);
    }

    async function removeTask (tid: number) {
        await deleteTask(tid).then(()=>{
            loadTasks();
        })
    }
    
    function addTask () {
        if(id) {
            getCurrentUser().then((user)=>{
                if(user){
                    pushTaskForProduct({product_id: Number(id), owner_id: user.id, admin_made: true}).then((results) => {
                        if (results) {
                            const tid = results;
                            editTask(tid);
                        }
                    })
                }
            })
        }
    }

    function loadTasks () {
        if(id) {
            doesProductRequireFragment(id).then((result)=>{
                if(result !== undefined && result !== null) {
                    setFragment(result);
                    getTasksForProduct(Number(id)).then((tasks)=>{
                        if (tasks) 
                        {
                            setTasks(tasks);
                            setLoading(false);
                        }
                    })
                }
            })
        }
    }

    useEffect(() => {
        loadTasks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    if (loading) return <Loading />
    return (
        <div className="flex w-full">
            <TaskList fragment={fragment} tasks={tasks} editTask={editTask} addTask={addTask} removeTask={removeTask} />
        </div>
    )
}
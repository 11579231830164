import React, { useEffect, useState } from 'react'
import { useInterval } from '../../hooks/useInterval';

type Props = {}

export default function TimeUntilExam({}: Props) {

    const deadline = Date.parse('2023-05-04');

    let now = Date.now();
    const diffTimestamp = deadline - now; 
    const diffDays = Math.floor(diffTimestamp / (1000 * 60 * 60 * 24)); 
    const [remainingDays, setRemainingDays] = useState(diffDays)
    const [color, setColor] = useState("")
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        now = Date.now();
        const diffTimestamp = deadline - now; 
        const diffDays = Math.floor(diffTimestamp / (1000 * 60 * 60 * 24)); 
        setRemainingDays(diffDays);
        setLoading(false);
        // eslint-disable-next-line 
    },)

    useInterval(() => {
        now = Date.now();
        const diffTimestamp = deadline - now; 
        const diffDays = Math.floor(diffTimestamp / (1000 * 60 * 60 * 24)); 
        setRemainingDays(diffDays);
    }, 10000);

    useEffect(()=>{

    }, [remainingDays])

  if ( loading ) return <></>;
  return (
    <div className='h-full w-full text-center flex flex-col justify-center'>
      <div>
        <div className='text-base md:text-lg font-semibold m-auto text-center'>
          Pozostało
        </div>
        <div className='h-16 md:h-20 text-orange-600 text-6xl md:text-7xl font-extrabold rounded-3xl text-center flex flex-col justify-center'>
          <div>
            {remainingDays}
          </div>
        </div>
        <div className='mt-1 text-base md:text-lg font-semibold m-auto text-center'>
          dni do matury 
        </div>
        <div className=' text-neutral-400 text-xs'>(2023)</div>
      </div>
    </div>
  )
}
import { debounce, DebouncedFunc } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Task } from '../../assets/types';
import { useWindowSize } from '../../hooks/useWindowsSize';
import SavingStateDisplay from '../Outputs/SavingStateDisplay';

type Props = {
    id: string;
    label: string;
    description?: string;
    valueName: string;
    firstText: string;
    instantSaving: boolean;
    save(id: number, updatedData: Partial<Task>): Promise<void> | void
}

const areaStyle = {
    tabSize: "40px"
}

export default function BigInput({id, label, valueName, description, firstText, save, instantSaving}: Props) {
    const smallInputRef = useRef<HTMLTextAreaElement>(null);
    const [text, setText] = useState(firstText ? firstText : "");
    const [saved, setSaved] = useState(false)
    const size = useWindowSize();

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(event.target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === "Tab") {
        event.preventDefault();
        const start = event.currentTarget.selectionStart;
        const end = event.currentTarget.selectionEnd;
        const newText = text.substring(0, start) + "\t" + text.substring(end);
        setText(newText);
        setTimeout(() => {
            if (smallInputRef && smallInputRef.current) {
            smallInputRef.current.selectionStart = start + 1;
            smallInputRef.current.selectionEnd = start + 1;
            }
        }, 0);
        }
        /* if (event.key === "Enter") {
        event.preventDefault();
        event.currentTarget.blur();
        const start = event.currentTarget.selectionStart;
        const end = event.currentTarget.selectionEnd;
        const newText = text.substring(0, start) + "\n" + text.substring(end);
        setText(newText);
        setTimeout(() => {
            if (smallInputRef && smallInputRef.current) {
            smallInputRef.current.selectionStart = start + 1;
            smallInputRef.current.selectionEnd = start + 1;
            }
        }, 0);
        } */
    };

    function matchHeight () {
        if (smallInputRef.current) {
            smallInputRef.current.style.height = "";
            smallInputRef.current.style.height = `${smallInputRef.current.scrollHeight+4}px`;
        }
    }

    useEffect(()=>{
        matchHeight();
    }, [text, size])

    const debouncedSave: DebouncedFunc<(text: string) => Promise<void>> = useCallback(
        debounce(async (text: string) => {
            if (text !== "") {
                save(Number(id), {[valueName]: text});
                setSaved(true)
            }
    }, 1500), []);

    useEffect(() => {
        setSaved(false)
        if (text && !instantSaving) {
            debouncedSave(text);
        }
        else
        {
            save(Number(id), {[valueName]: text});
        }
    }, [text, debouncedSave]);

    useEffect(() => {
        const handleSave = (event: KeyboardEvent) => {
          if ((event.ctrlKey|| event.metaKey) && event.key === 's') {
            event.preventDefault();
            if (text !== "") {
                save(Number(id), {[valueName]: text});
                setSaved(true)
            }
          }
        };
    
        document.addEventListener('keydown', handleSave);
    
        return () => {
          document.removeEventListener('keydown', handleSave);
        };
    });

    useEffect(()=>{
        save(Number(id), {[valueName]: text});
    })

    return (
        <div className='flex flex-col gap-2'>
            <div className='text-neutral-700 dark:text-neutral-200 font-semibold text-xl'>
                {label}
            </div>
            { description ? (
            <div className='text-neutral-700 dark:text-neutral-200 font-normal text-md'>
                {description}
            </div>
            ) : ("")}
            <textarea 
                id={valueName}
                className='text-left w-full rounded-lg resize-none dark:bg-neutral-600 bg-neutral-100 text-neutral-800 dark:text-neutral-200 border-neutral-300 dark:border-neutral-500 border-2 p-1'
                style={areaStyle}
                ref={smallInputRef}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                onInput={handleChange}
                value={text}
                onBlur={()=>{save(Number(id), {[valueName]: text})}}
            />
            { !instantSaving ? <SavingStateDisplay saved={saved} /> : "" }
        </div>
    )
}
import React, { useEffect, useState } from 'react'
import { PopUpOptions } from '../../../assets/types';
import { gradingTablesHeaders } from '../../../data/constants';
import { Highlighter } from '../../../layouts/TestGrading';
import { useWindowSize } from '../../../hooks/useWindowsSize';

type PopUpProps = {
    id: string,
    top: number,
    left: number,
    width: number,
    product_id: string,
    highlighter: Highlighter,
    setShowPopup: (showPopup: boolean) => void
    setShowAnnotations: (showAnnotations: boolean) => void
}

export default function PopUp({ id, top, left, width, product_id, highlighter, setShowPopup, setShowAnnotations }: PopUpProps) {

    const windowSize = useWindowSize();

    // THIS DATA NEEDS TO BE UPDATED WHENEVER CHANGES ARE APPLIED TO THE POPUP DIMENSIONS
    const popupHeight = 38;
    const [position, setPosition] = useState(null as {top: number, left: number} | null);
    
    const gradingTable = gradingTablesHeaders.find((table)=>(table.product_id.toString() === product_id));

    let options = [] as PopUpOptions[];

    if(gradingTable) {
        gradingTable.headers.forEach((header)=>{
            options.push({ text: header.label, action: header.label, color_light: "black", color_dark: "white"})
        })
    }

    options.push({ text: 'Usuń', action: 'Delete', color_dark: "red", color_light: "red"});

    
    const popupWidth = options.map((option)=>(option.text.length*12)).reduce((a,b)=>(a+b),0) + 22.5*options.length;

    function handleClick (action: string) {
        highlighter.handlePopup(action);
        window.getSelection()?.removeAllRanges();
        highlighter.lastHighlighyId = id;
        setShowAnnotations(true);
        setShowPopup(false);
    }
    function handleRepositionPopup() {
        if (highlighter.tempHighlight && highlighter.tempHighlight.position) {
            const top = highlighter.tempHighlight?.position.top - popupHeight - 4;
            const hlleft = highlighter.tempHighlight?.position.left;
    
            const maxWidth = windowSize.width <= 768 ? 350 : 700;
            const left = Math.min(maxWidth - popupWidth, Math.max(0, hlleft - (popupWidth / 2)));
    
            setPosition({ top: top, left: left });
        }
    }

    useEffect(() => { 
        handleRepositionPopup();
        // eslint-disable-next-line
    },[]);

    useEffect(() => {
        window.addEventListener("reposition_popup", handleRepositionPopup);
        return () => window.removeEventListener("reposition_popup", handleRepositionPopup);
        // eslint-disable-next-line
    }, [highlighter, width]);

    if (!position) return (<></>);
    return (
        <div id="magicPopup" style={{position: "absolute", top: position.top, left: position.left}} className='grid grid-flow-col gap-1 p-1 z-[1001] bg-neutral-200 dark:bg-neutral-800 w-fit rounded-[10px] divide-x-1 border border-neutral-300 dark:border-neutral-600 dark:shadow-neutral-900 shadow-neutral-400 shadow-md'>
            {options.map((option, i)=>(
                <React.Fragment key={i}>
                    <button type="button" onClick={()=>(handleClick(option.action) )} className={
                        'text-' + option.color_light + ' dark:text-' + option.color_dark + ' px-2.5 py-1 rounded-md bg-inherit font-semibold hover:bg-[rgb(200,200,200)] hover:dark:bg-neutral-700'}>
                    {option.text}
                    </button>
                </React.Fragment>
            ))}
        </div>
    )
}

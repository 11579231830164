import React, { useEffect, useState } from 'react'
import CentileGraph from '../../../components/Graphs/CentileGraph';
import CentileInfo from '../../../components/Tile/CentileInfo';
import DashboardTile from '../../../components/Tile/DashboardTile'
import Loading from '../../../layouts/Loading';
import MostCommonError from '../../../components/Tile/MostCommonError';
import RingGraph from '../../../components/Graphs/RingGraph';
import RingGrapshDesc from '../../../components/Tile/RingGrapshDesc';
import TimeUntilExam from '../../../components/Tile/TimeUntilExam';
import { getCurrentUserData } from '../../../services/UsersData';
import Greetings from '../../../components/Outputs/Greetings';
import TilesLayout from '../../../layouts/TilesLayout';

export default function StudentDashboard() {

    const [name, setName] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getCurrentUserData().then((user)=>{
            if (user) {
                setName(user.name);
                setLoading(false);
            }
        })
    })

    if (loading) return <Loading />;
    return (
        <>
            <Greetings name={name}/>
            <TilesLayout>
                <DashboardTile colspan={1} rowspan={1}>
                    <RingGraph values={[60,53,70]}/>
                </DashboardTile>
                <DashboardTile colspan={1} rowspan={1}>
                    <RingGrapshDesc />
                </DashboardTile>
                <DashboardTile colspan={2} rowspan={1}>
                    <CentileGraph/>
                </DashboardTile>
                <DashboardTile colspan={2} rowspan={1}>
                    <CentileInfo />
                </DashboardTile>
                <DashboardTile colspan={1} rowspan={1}>
                    <MostCommonError />
                </DashboardTile>
                <DashboardTile colspan={1} rowspan={1}> 
                    <TimeUntilExam />
                </DashboardTile>
            </TilesLayout>
        </>
    )
}

import { GradingTableHeaders, RingColorType } from "../assets/types";

export const examColors = [
  {
      "exam": "Matura",
      "generation": "2015",
      "main": "#F8A426",
      "light": "#fcd69c",
      "dark": "#945b05",
  },
  {
      "exam": "Matura",
      "generation": "2023",
      "main": "#745DC5",
      "light": "#beb4e4",
      "dark": "#382970",
  },
  {
      "exam": "E8",
      "generation": "2019",
      "main": "#6DBD45",
      "light": "#b5dea1",
      "dark": "#365e21",
  }
]

export const statusColors = {
    ok: {
        color: "rgb(16 185 129)",
        threshold: 50,
    },
    warning: {
        color: "rgb(217 119 6)",
        threshold: 30,
    },
    bad: {
        color: "rgb(217, 38, 38)",
        threshold: 0
    }
}

export const fallbackBgColor = "#919191";
export const fallbackColor = "#4d4d4d";

export const statusCodes = [
    "Kupiono",
    "Do napisania",
    "W trakcie pisania",
    "Wysłano",
    "W trakcie sprawdzania",
    "Sprawdzony",
    "Obejrzany"
]

export const tabStyle = {
    tabSize: "40px"
}

export const popUpOptions = [
    { text: 'Usuń', action: 'Delete', color: "#ff0000E0"}
]

export const zLimit = 1000; // Highest Z-Index for a highlight
export const midSeparator = " [...] ";
export const maxQuoteLength = 10;


export const gradingTablesHeaders: GradingTableHeaders[] = [
    {
        product_id: 9,
        headers: 
            [ // E8 2019 Angielski
                {
                    label: "A",
                    description: "Treść",
                    minPoints: 0,
                    maxPoints: 4
                },
                {
                    label: "B",
                    description: "Spójność i logika wypowiedzi",
                    minPoints: 0,
                    maxPoints: 2
                },
                {
                    label: "C",
                    description: "Zakres środków językowych",
                    minPoints: 0,
                    maxPoints: 2
                },
                {
                    label: "D",
                    description: "Poprawność środków językowych",
                    minPoints: 0,
                    maxPoints: 2
                },
            ],
    },
    {
        product_id: 8,
        headers: 
            [ // E8 2019 Polski
                {
                    label: "A",
                    description: "Realizacja tematu wypowiedzi",
                    minPoints: 0,
                    maxPoints: 2
                },
                {
                    label: "B",
                    description: "Elementy twórcze / elementy retoryczne",
                    minPoints: 0,
                    maxPoints: 5
                },
                {
                    label: "C",
                    description: "Kompetencje literackie i kulturowe",
                    minPoints: 0,
                    maxPoints: 2
                },
                {
                    label: "D",
                    description: "Kompozycja tekstu",
                    minPoints: 0,
                    maxPoints: 2
                },
                {
                    label: "E",
                    description: "Styl",
                    minPoints: 0,
                    maxPoints: 2
                },
                {
                    label: "F",
                    description: "Język",
                    minPoints: 0,
                    maxPoints: 4
                },
                {
                    label: "G",
                    description: "Ortografia",
                    minPoints: 0,
                    maxPoints: 2
                },
                {
                    label: "H",
                    description: "Interpunkcja",
                    minPoints: 0,
                    maxPoints: 1
                },
            ],
    },
    {
        product_id: 14,
        headers: 
            [ // Matura 2023 Polski Podstawowy
                {
                    label: "1",
                    description: "Spełnienie formalnych warunków polecenia",
                    minPoints: 0,
                    maxPoints: 1
                },
                {
                    label: "2",
                    description: "Kompetencje literackie i kulturowe",
                    minPoints: 0,
                    maxPoints: 8
                },
                {
                    label: "3a",
                    description: "Struktura wypowiedzi",
                    minPoints: 0,
                    maxPoints: 3
                },
                {
                    label: "3b",
                    description: "Spójność wypowiedzi",
                    minPoints: 0,
                    maxPoints: 3
                },
                {
                    label: "3c",
                    description: "Styl wypowiedzi",
                    minPoints: 0,
                    maxPoints: 1
                },
                {
                    label: "4a",
                    description: "Zakres i poprawność środków językowych",
                    minPoints: 0,
                    maxPoints: 7
                },
                {
                    label: "4b",
                    description: "Poprawność ortograficzna",
                    minPoints: 0,
                    maxPoints: 2
                },
                {
                    label: "4c",
                    description: "Poprawność interpunkcyjna",
                    minPoints: 0,
                    maxPoints: 2
                },
            ],
    },
    {
        product_id: 15,
        headers: 
            [ // Matura 2023 Polski Rozszerzony
                {
                    label: "1",
                    description: "Spełnienie formalnych warunków polecenia",
                    minPoints: 0,
                    maxPoints: 1
                },
                {
                    label: "2",
                    description: "Kompetencje literackie i kulturowe",
                    minPoints: 0,
                    maxPoints: 8
                },
                {
                    label: "3a",
                    description: "Struktura wypowiedzi",
                    minPoints: 0,
                    maxPoints: 3
                },
                {
                    label: "3b",
                    description: "Spójność wypowiedzi",
                    minPoints: 0,
                    maxPoints: 3
                },
                {
                    label: "3c",
                    description: "Styl wypowiedzi",
                    minPoints: 0,
                    maxPoints: 1
                },
                {
                    label: "4a",
                    description: "Zakres i poprawność środków językowych",
                    minPoints: 0,
                    maxPoints: 7
                },
                {
                    label: "4b",
                    description: "Poprawność ortograficzna",
                    minPoints: 0,
                    maxPoints: 2
                },
                {
                    label: "4c",
                    description: "Poprawność interpunkcyjna",
                    minPoints: 0,
                    maxPoints: 2
                },
            ],
    },
    {
        product_id: 16,
        headers: 
            [ // Matura 2023 Angielski Podstawowy
                {
                    label: "A",
                    description: "Treść",
                    minPoints: 0,
                    maxPoints: 5
                },
                {
                    label: "B",
                    description: "Spójność i logika wypowiedzi",
                    minPoints: 0,
                    maxPoints: 2
                },
                {
                    label: "C",
                    description: "Zakres środków językowych",
                    minPoints: 0,
                    maxPoints: 3
                },
                {
                    label: "D",
                    description: "Poprawność środków językowych",
                    minPoints: 0,
                    maxPoints: 2
                },
            ],
    },
    {
        product_id: 17,
        headers: 
            [ // Matura 2023 Angielski Podstawowy
                {
                    label: "A",
                    description: "Zgodność z poleceniem",
                    minPoints: 0,
                    maxPoints: 5
                },
                {
                    label: "B",
                    description: "Spójność i logika wypowiedzi",
                    minPoints: 0,
                    maxPoints: 2
                },
                {
                    label: "C",
                    description: "Zakres środków językowych",
                    minPoints: 0,
                    maxPoints: 3
                },
                {
                    label: "D",
                    description: "Poprawność środków językowych",
                    minPoints: 0,
                    maxPoints: 3
                },
            ],
    },
    {
        product_id: 10,
        headers: 
            [ // Matura 2015 Polski Podstawowy
                {
                    label: "A",
                    description: "Sformułowanie stanowiska wobec problemu podanego w poleceniu (0-6) / Koncepcja interpretacyjna (0-9)",
                    minPoints: 0,
                    maxPoints: 9
                },
                {
                    label: "B",
                    description: "Uzasadnienie stanowiska (0-18) / Uzasadnienie tezy interpretacyjnej (0-15)",
                    minPoints: 0,
                    maxPoints: 18
                },
                {
                    label: "C",
                    description: "Poprawność rzeczowa",
                    minPoints: 0,
                    maxPoints: 4
                },
                {
                    label: "D",
                    description: "Zamysł kompozycyjny",
                    minPoints: 0,
                    maxPoints: 6
                },
                {
                    label: "E",
                    description: "Spójność lokalna",
                    minPoints: 0,
                    maxPoints: 2
                },
                {
                    label: "F",
                    description: "Styl tekstu",
                    minPoints: 0,
                    maxPoints: 4
                },
                {
                    label: "G",
                    description: "Poprawność językowa",
                    minPoints: 0,
                    maxPoints: 6
                },
                {
                    label: "H",
                    description: "Poprawność zapisu",
                    minPoints: 0,
                    maxPoints: 4
                },
            ],
    },
    {
        product_id: 11,
        headers: 
            [ // Matura 2015 Polski Rozszerzony
                {
                    label: "A",
                    description: "Określenie problemu (0-9) / Koncepcja porównywania utworów  (0-6)",
                    minPoints: 0,
                    maxPoints: 9
                },
                {
                    label: "B",
                    description: "Sformułowanie stanowiska wobec rozwiązania przyjętego przez autora tekstu (0-9) / Uzasadnienie tezy interpretacyjnej (0-12)",
                    minPoints: 0,
                    maxPoints: 12
                },
                {
                    label: "C",
                    description: "Poprawność rzeczowa",
                    minPoints: 0,
                    maxPoints: 2
                },
                {
                    label: "D",
                    description: "Zamysł kompozycyjny",
                    minPoints: 0,
                    maxPoints: 6
                },
                {
                    label: "E",
                    description: "Spójność lokalna",
                    minPoints: 0,
                    maxPoints: 2
                },
                {
                    label: "F",
                    description: "Styl tekstu",
                    minPoints: 0,
                    maxPoints: 4
                },
                {
                    label: "G",
                    description: "Poprawność językowa",
                    minPoints: 0,
                    maxPoints: 4
                },
                {
                    label: "H",
                    description: "Poprawność zapisu",
                    minPoints: 0,
                    maxPoints: 4
                },
            ],
    },
    {
        product_id: 12,
        headers: 
            [ // Matura 2015 Angielski Podstawowy
                {
                    label: "A",
                    description: "Treść",
                    minPoints: 0,
                    maxPoints: 4
                },
                {
                    label: "B",
                    description: "Spójność i logika wypowiedzi",
                    minPoints: 0,
                    maxPoints: 2
                },
                {
                    label: "C",
                    description: "Zakres środków językowych",
                    minPoints: 0,
                    maxPoints: 2
                },
                {
                    label: "D",
                    description: "Poprawność środków językowych",
                    minPoints: 0,
                    maxPoints: 2
                },
            ],
    },
    {
        product_id: 13,
        headers: 
            [ // Matura 2015 Angielski Podstawowy
                {
                    label: "A",
                    description: "Zgodność z poleceniem",
                    minPoints: 0,
                    maxPoints: 5
                },
                {
                    label: "B",
                    description: "Spójność i logika wypowiedzi",
                    minPoints: 0,
                    maxPoints: 2
                },
                {
                    label: "C",
                    description: "Zakres środków językowych",
                    minPoints: 0,
                    maxPoints: 3
                },
                {
                    label: "D",
                    description: "Poprawność środków językowych",
                    minPoints: 0,
                    maxPoints: 3
                },
            ],
    }
]

export const highlightColor = "rgba(255, 0, 0, 0.5)";
//export const essayGreen = "#008f50";

export const ringGraphDefaultOptions: RingColorType[] = [
    {
        main: "#008f50",
        light: "#4dffb2",
        dark: "#00331d",
    },
    {
        main: "#f49d0b",
        light: "#fad89e",
        dark: "#493003",
    },
    {
        main: "#185abd",
        light: "#a5c4f3",
        dark: "#0c2b5a",
    }
]
import React, { useState, useEffect } from 'react';

const Clock: React.FC = () => {
  const [time, setTime] = useState<string>('');

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      setTime(`${hours}:${minutes}:${seconds}`);
    };

    // Uruchamiamy funkcję updateTime co sekundę
    const intervalId = setInterval(updateTime, 1000);

    // Czyszczenie interwału w momencie, gdy komponent zostaje odmontowany
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <h1>Obecny czas:</h1>
      <p>{time}</p>
    </div>
  );
};

export default Clock;

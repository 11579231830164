import { useState, useEffect } from "react";

export function usePwa(): boolean {
  const [isPwa, setIsPwa] = useState(false);

  useEffect(() => {
    function checkIsPwa() {
      const isPwa =
        window.matchMedia("(display-mode: standalone)").matches ||
        window.navigator.userAgent.includes("standalone");
      setIsPwa(isPwa);
    }

    checkIsPwa();
    window.addEventListener("beforeinstallprompt", checkIsPwa);
    return () => {
      window.removeEventListener("beforeinstallprompt", checkIsPwa);
    };
  }, []);

  return isPwa;
}

import { Tooltip } from 'antd';
import React from 'react'
import { FiInfo } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { updateEssay } from '../../services/EssaysData';

type Props = {
    essay_id: number;
    header: string;
    content: string;
    source: string;
    role: string;
}

export default function EssayTaskDisplay({essay_id, header, content, source, role}: Props) {

    const navigate = useNavigate();

    function handleChange() {
        updateEssay(essay_id, {status: 1}).then(()=>{
            navigate(`/student/write/before-you-proceed/${essay_id}`);
        })
    }

    return (
        <div className='w-full flex flex-col p-3 rounded-2xl text-neutral-800 dark:text-neutral-50  bg-neutral-200 dark:bg-neutral-700 border border-neutral-300 dark:border-neutral-600'>
            <div className='w-full h-fit flex flex-row text-neutral-800 dark:text-neutral-50'>
                <div className='w-full h-fit text-2xl font-bold mb-1 '>
                    {header}
                </div>
                <div className='h-full flex flex-col my-auto'>
                    <Tooltip title={source} showArrow={false}>
                        <FiInfo size={20} />
                    </Tooltip>
                </div>
            </div>
            <div className='text-xs text-neutral-800 dark:text-neutral-50 whitespace-pre-wrap text-justify'>
                {content}
            </div>
            <div className='h-full'>

            </div>
            { role === "write" ? <button onClick={handleChange} className='px-2 py-2 mt-1 text-xs font-light w-fit rounded-xl bg-neutral-200 dark:bg-neutral-700 border border-neutral-300 dark:border-neutral-600'>
                Zmień polecenie
            </button> : "" }
        </div>
    )
}
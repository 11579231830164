import React, { useEffect } from 'react'
import { useDarkMode } from '../../hooks/useDarkMode';

export default function CentileGraph() {

    const data = [5.67,3.33,1,1.5,2,3,4,5,3,1,1,1,1,1,1,1,1,1,1.5,2,1.67,1.33,1,1.5,2,1.67,1.33,1,1.5,2,2,2,2,2.5,3,3,3,3,3.5,4,4.33,4.67,5,4.5,4,5,6,7,5.5,4,5,6,7,6,5,5,5,5,5.5,6,5.33,4.67,4,4.5,5,5.33,5.67,6,5,4,4,4,4,5,6,5.33,4.67,4,4,4,3.67,3.33,3,3.5,4,3.33,2.67,2,3,4,3.33,2.67,2,2.5,3,2.33,1.67,1,1.5,2]
    const isDarkMode = useDarkMode();

    function showData(data: Array<number>){
        
        const canvas = document.getElementById("CentileGraph") as HTMLCanvasElement;
        const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
        const lineColor = isDarkMode ? "white" : "black";

        const w = canvas.width;
        const h = canvas.height;
        const m = 30;

        const lineW = 40;
        const smallLineW = 20;
        let val /* = 0.35 */;
        const maxY = h - 2*m - lineW - (3/2)*smallLineW;
        //console.log(maxY);

        ctx.beginPath();
        ctx.strokeStyle = '#00a640';
        ctx.lineWidth = lineW;
        ctx.lineCap = 'round';
        ctx.moveTo(m+0.5*lineW,h-m-0.5*lineW);
        ctx.lineTo(w-m-0.5*lineW,h-m-0.5*lineW)
        ctx.stroke();

        ctx.beginPath();
        ctx.strokeStyle = 'orange';
        ctx.lineWidth = lineW;
        ctx.lineCap = 'round';
        ctx.moveTo(m+0.5*lineW,h-m-0.5*lineW);
        ctx.lineTo(w*(2/3)-m-0.5*lineW,h-m-0.5*lineW)
        ctx.stroke();

        ctx.beginPath();
        ctx.strokeStyle = 'red';
        ctx.lineWidth = lineW;
        ctx.lineCap = 'round';
        ctx.moveTo(m+0.5*lineW,h-m-0.5*lineW);
        ctx.lineTo(w*(1/3)-m-0.5*lineW,h-m-0.5*lineW)
        ctx.stroke();

        data.forEach((item, i) => {
            //console.log(item, i)
            val = item
            ctx.beginPath();
            ctx.strokeStyle = i===67 ? "#00a640" : lineColor;
            ctx.lineWidth = smallLineW;
            ctx.lineCap = 'round';
            ctx.moveTo(m+0.5* smallLineW + 1.5*i*smallLineW,m+maxY+0.5*smallLineW);
            ctx.lineTo(m+0.5* smallLineW + 1.5*i*smallLineW,m+maxY+0.5*smallLineW-(val/7)*maxY)
            ctx.stroke();
        })
    }
        useEffect(()=> {
            showData(data)
        }, [isDarkMode])
        
    return (
        <div>
            <div className=' text-base font-semibold'><span className=' text-[#00a640]'>Twój</span> wynik na tle innych</div>
            <canvas id="CentileGraph" className='w-[304px] md:w-[368px] h-[104px] md:h-[132px]' width="3050" height="1525"></canvas>
        </div>
    )
}

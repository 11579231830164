import React, { ReactNode } from 'react'

interface TilesLayourProps {
    children: ReactNode;
}

const TilesLayout: React.FC<TilesLayourProps> = ({ children }) => {
    return (
        <div className='grid w-max grid-cols-2 sm:grid-cols-3 md:grid-cols-4 2xl:grid-cols-6 gap-4'>
            {children}
        </div>
    )
}

export default TilesLayout
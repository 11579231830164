import { Essay, Product } from "../assets/types";
import { supabase } from "./client";
import { getCurrentUser } from "./UsersData";

export async function getProductsAll () {
    try {
        let results = await supabase
            .from('products')
            .select("*")
        if (results.error && results.status !== 406) {
            throw results.error
        } else if (results.data) {
        return results.data as Product[];
        }
    } catch (error) {
        console.error(error)
    }
}

export async function getEssaysAll () {
    try {
        let results = await supabase
            .from('essays')
            .select("*")
        if (results.error && results.status !== 406) {
            throw results.error
        } else if (results.data) {
        return results.data as Essay[];
        }
    } catch (error) {
        console.error(error)
    }
}

export async function getEssayProduct(essay_id: string) {
    try {
        const { data, error } = await supabase
            .from('essays')
            .select('products(*)')
            .eq('id', essay_id)
            .single()
        if (error) throw error;
        if (data) return data.products[0] as Product;
    } catch (error) {
        console.error(error)
    }
}

export async function getProductById (product_id: string) {
    try {
        let { error, status, data } = await supabase
            .from('products')
            .select("*")
            .eq("id", product_id)
            .single()
        if (error && status !== 406) {
            throw error
        } else if (data) {
        return data as Product;
        }
    } catch (error) {
        console.error(error)
    }
}

export async function doesEssayRequireFragment (essay_id: string) {
    try {
        let { error, status, data } = await supabase
            .from('essays')
            .select("products(is_fragment_required)")
            .eq("id", essay_id)
            .single()
        if (error && status !== 406) {
            throw error
        } else if (data && data?.products[0] as Product && (data.products[0] as Product).is_fragment_required) {
            return (data.products[0] as Product).is_fragment_required as boolean;
        }
    } catch (error) {
        console.error(error)
    }
}

export async function doesProductRequireFragment (product_id: string) {
    try {
        let { error, status, data } = await supabase
            .from('products')
            .select("is_fragment_required")
            .eq("id", product_id)
            .single()
        if (error && status !== 406) {
            throw error
        } else if (data !== undefined && data !== null) {
            return data.is_fragment_required as boolean;
        }
    } catch (error) {
        console.error(error)
    }
}

export async function getEssaysForCurrentUser () {
    try {
    const user = await getCurrentUser();
        let { error, status, data } = await supabase
            .from('essays')
            .select(`
            *,
            user:user_id(*),
            teacher:teacher_id(*),
            task:tasks(*, subtype:subtypes(*)),
            product:products(*)
            `)
            .match({'user_id': user?.id})
        if (error && status !== 406) {
            throw error
        } else if (data) {
            return data as Essay[];
        }
    } catch (error) {
        console.error(error)
    }
}

export async function getEssaysBoughtByCurrentUser () {
    try {
    const user = await getCurrentUser();
        let { error, status, data } = await supabase
            .from('essays')
            .select(`
            *,
            user:user_id(*),
            teacher:teacher_id(*),
            task:tasks(*, subtype:subtypes(*)),
            product:products(*)
            `)
            .or('status.eq.1, status.eq.2')
            .eq('user_id', user?.id)
        if (error && status !== 406) {
            throw error
        } else if (data) {
            return data as Essay[];
        }
    } catch (error) {
        console.error(error)
    }
}

export async function getEssaysSentByCurrentUser () {
    try {
    const user = await getCurrentUser();
        let { error, status, data } = await supabase
            .from('essays')
            .select(`
            *,
            user:user_id(*),
            teacher:teacher_id(*),
            task:tasks(*, subtype:subtypes(*)),
            product:products(*)
            `)
            .or('status.eq.3, status.eq.4, status.eq.5, status.eq.6')
            .eq('user_id', user?.id)
        if (error && status !== 406) {
            throw error
        } else if (data) {
            return data as Essay[];
        }
    } catch (error) {
        console.error(error)
    }
}

export async function getEssaysAvailableForReservation () {
    try {
        let { error, status, data } = await supabase
            .from('essays')
            .select(`
            *,
            user:user_id(*),
            teacher:teacher_id(*),
            task:tasks(*, subtype:subtypes(*)),
            product:products(*)
            `)
            .eq('status', 3)
        if (error && status !== 406) {
            throw error
        } else if (data) {
            return data as Essay[];
        }
    } catch (error) {
        console.error(error)
    }
}

export async function getEssaysReservedByCurrentUser () {
    try {
        const user = await getCurrentUser();
        let { error, status, data } = await supabase
            .from('essays')
            .select(`
            *,
            user:user_id(*),
            teacher:teacher_id(*),
            task:tasks(*, subtype:subtypes(*)),
            product:products(*)
            `)
            .match({'status': 4, 'teacher_id': user?.id})
        if (error && status !== 406) {
            throw error
        } else if (data) {
            return data as Essay[];
        }
    } catch (error) {
        console.error(error)
    }
}

export async function getEssaysCheckedByCurrentUser () {
    try {
        const user = await getCurrentUser();
        let { error, status, data } = await supabase
            .from('essays')
            .select(`
            *,
            user:user_id(*),
            teacher:teacher_id(*),
            task:tasks(*, subtype:subtypes(*)),
            product:products(*)
            `)
            .or('status.eq.5, status.eq.6')
            .eq('teacher_id', user?.id)
        if (error && status !== 406) {
            throw error
        } else if (data) {
            return data as Essay[];
        }
    } catch (error) {
        console.error(error)
    }
}

export async function getEssayForCurrentUser ( essay_id: string ) {
    try {
        const user = await getCurrentUser();
        let { error, status, data } = await supabase
            .from('essays')
            .select(`
            *,
            user:user_id(*),
            teacher:teacher_id(*),
            task:tasks(*, subtype:subtypes(*)),
            product:products(*)
            `)
            .match({'user_id': user?.id, 'id': essay_id})
            .single()
        if (error && status !== 406) {
            throw error
        } else if (data) {
            return data as Essay;
        }
    }
    catch (error)
    {
        console.error(error)
    }
}

export async function getEssayForCurrentTeacher ( essay_id: string ) {
    try {
        const user = await getCurrentUser();
        let { error, status, data } = await supabase
            .from('essays')
            .select(`
            *,
            user:user_id(*),
            teacher:teacher_id(*),
            task:tasks(*, subtype:subtypes(*)),
            product:products(*)
            `)
            .match({'teacher_id': user?.id, 'id': essay_id})
            .single()
        if (error && status !== 406) {
            throw error
        } else if (data) {
            return data as Essay;
        }
    }
    catch (error)
    {
        console.error(error)
    }
}

export async function pushEssay ( product_id: string, isQuick: boolean )
{
    try {
        const user = await getCurrentUser();
        let { error, status } = await supabase
            .from('essays')
            .insert({user_id: user?.id, product_id: product_id, status: 1, is_quick: isQuick})
        if (error && status !== 406) throw error;
    }
    catch (error)
    {
        console.error(error)
    }
}

export async function updateEssayContent (essay_id: number, content: string) {
    try {
        const user = await getCurrentUser();
        let { error, status } = await supabase
            .from('essays')
            .update({'content': content})
            .match({'id': essay_id, 'user_id': user?.id})
        if (error && status !== 406) throw error
    } catch (error) {
        console.error(error)
    }
}

export async function updateEssay (essay_id: number, data: any) {
    try {            
        let { error, status } = await supabase
        .from('essays')
        .update(data)
        .eq('id', essay_id)
        
        if (error && status !== 406) {
            throw error
        }
    } catch (error) {
        console.error(error)
    }
}

export async function setEssayTask (essay_id: number, task_id: number, requiresFragment: boolean) {
    try {
        if(!requiresFragment) {
            let { error, status } = await supabase
                .from('essays')
                .update({"justTask_id": task_id})
                .eq('id', essay_id)
                .select("*")
            if (error && status !== 406) {
                throw error
            }
        } else {
            const { error, status } = await supabase
                    .from('essays')
                    .update({"task_id": task_id})
                    .eq('id', essay_id)
                    .select("*")
            if (error && status !== 406) {
                throw error
            }
        }
    } catch (error) {
        console.error(error)
    }
}

export async function getEssayStatus ( essay_id: string ) {
    try {
        const user = await getCurrentUser();
        let { error, status, data } = await supabase
            .from('essays')
            .select("status")
            .match({'user_id': user?.id, 'id': essay_id})
            .single()
        if (error && status !== 406) {
            throw error
        } else if (data) {
            return data.status as number;
        }
    }
    catch (error)
    {
        console.error(error)
    }
}
import { AuthResponse, Session, User } from '@supabase/supabase-js';
import { useContext, useState, useEffect, createContext } from 'react';
import { supabase } from '../services/client';
  
// define the context type for the auth context
type AuthContextType = {
    auth: boolean;
    session: Session | null | undefined;
    user: User | null | undefined;
    signIn: (credentials: { email: string, password: string }) => Promise<AuthResponse>;
    signUp: (credentials: { email: string, password: string }) => Promise<AuthResponse>;
    signOut: () => Promise<any>;
    resetPassword: (email:string) => Promise<any>;
    updatePassword: (updatedPassword:string) => Promise<any>;
}

// create a context for authentication
const AuthContext = createContext<AuthContextType>({
    auth: false,
    session: null,
    user: null,
    signIn: (credentials: { email: string, password: string }) => Promise.resolve(signIn(credentials)),
    signUp: (credentials: { email: string, password: string }) => Promise.resolve(signUp(credentials)),
    signOut: () => Promise.resolve(),
    resetPassword: (email:string) => Promise.resolve(),
    updatePassword: (updatedPassword:string) => Promise.resolve(),
});

// auth methods
const signUp = (credentials: { email: string, password: string }) => supabase.auth.signUp(credentials)

const signIn = (credentials: { email: string, password: string }) =>  supabase.auth.signInWithPassword(credentials);

const signOut = () => supabase.auth.signOut();

const updatePassword = (updatedPassword:string) => supabase.auth.updateUser({ password: updatedPassword });

const resetPassword = (email:string) => supabase.auth.resetPasswordForEmail(email, { redirectTo: "" });

// create a provider to wrap the app and provide the auth context
export const AuthProvider = ({ children }: any) => {
    const [auth, setAuth] = useState(false);
    const [user, setUser] = useState<User>()
    const [session, setSession] = useState<Session | null>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const setData = async () => {
            const { data: { session }, error } = await supabase.auth.getSession();
            if (error) throw error;
            setSession(session)
            setUser(session?.user)
            setAuth(session?.user ? true : false);
            setLoading(false);
        };

        const { data: listener } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session);
            setUser(session?.user)
            setAuth(session?.user ? true : false);
            setLoading(false)
        });

        setData();

        return () => {
            listener?.subscription.unsubscribe();
        };
    }, []);

    const value = {
        auth,
        session,
        user,
        signIn,
        signUp,
        signOut,
        resetPassword,
        updatePassword
    };

    // use a provider to pass down the value
    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};
  
// export the useAuth hook
export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthProvider;
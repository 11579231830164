import React from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from '../../../components/Sidebar/Sidebar'
import UserPageLayout from '../../../layouts/UserPageLayout';

export default function Student() {

  return (
        <div className='flex'>
            <Sidebar role={"student"}/>
            <UserPageLayout>
                <Outlet/>
            </UserPageLayout>
        </div>
    )
}

import React from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from '../../../components/Sidebar/Sidebar'
import UserPageLayout from '../../../layouts/UserPageLayout'

export default function Teacher() {

    return (
        <div className='flex'>
            <Sidebar role={"teacher"}/>
            <UserPageLayout>
                <Outlet/>
            </UserPageLayout>
        </div>
    )
}

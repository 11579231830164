import React from 'react'
import IdDisplay from './IdDisplay';

interface Props {
    children: React.ReactNode;
    eid: number;
    isSaved: boolean;
}

export default function Paper(props: Props) {
  return (
        <div id="textContainer" className='w-[350px] md:w-[700px] h-fit leading-relaxed font-medium text-sm text-neutral-800 dark:text-neutral-200'>
            <div className='flex'>
                <IdDisplay id={props.eid} /> 
                {props.isSaved ? <div className='text-neutral-500 text-xs my-auto pl-2'>Zapisano</div> : <div className=' text-neutral-300 text-xs my-auto pl-2'>Zapisywanie</div>}
            </div>
            <div id="paper" className='p-8 h-full rounded-2xl bg-neutral-200 dark:bg-neutral-700 border border-neutral-300 dark:border-neutral-600'>
                {props.children}
            </div>
        </div>               
  )
}

import React, { useState } from 'react'
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from "react-router-dom"

const SignUp = () => {

    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ passwordConfirm, setPasswordConfirm ] = useState("");
    const [ loading, setLoading ] = useState(false);
    const { signUp } = useAuth();
    let navigate = useNavigate();

    const handleSignUp = async (event: any) => {
        event.preventDefault();
        setLoading(true);
        try {
            if ( password !== passwordConfirm ) throw new Error("Hasło i jego powtórzenie są różne!");
            const {error} = await signUp({email, password});
            if (error) throw error;
            alert(`Sprawdź swój email! (${email})`);
            navigate("/");
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
          <div className="w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-xl">
              <h1 className="text-3xl font-semibold text-center text-purple-700 underline">
                  Zarejestruj się
              </h1>
              <form onSubmit={handleSignUp} className="mt-6">
                  <div className="mb-2">
                      <input
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder='Adres email'
                          className="block w-full px-4 py-2 mt-2 text-[purple-700] bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                      />
                  </div>
                  <div className="mb-2">
                      <input
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder='Hasło'
                          className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                      />
                  </div>
                  <div className="mb-2">
                      <input
                          type="password"
                          value={passwordConfirm}
                          onChange={(e) => setPasswordConfirm(e.target.value)}
                          placeholder='Powtórz hasło'
                          className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                      />
                  </div>
                  <p className="mt-8 text-xs font-light text-center text-gray-700">
                    {" "}
                    Klikając w poniższy przycisk, zgadzasz się z naszą {" "}
                    <a
                        href="/privacy-policy"
                        className="font-medium text-purple-600 hover:underline"
                    >
                        polityką prywatności
                    </a>
                    .
                  </p>
                  <div className="mt-6">
                      <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-purple-700 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600">
                          ZAREJESTRUJ SIĘ
                      </button>
                  </div>
              </form>

              <p className="mt-8 text-xs font-light text-center text-gray-700">
                  {" "}
                  Masz już konto?{" "}
                  <a
                      href="/signin"
                      className="font-medium text-purple-600 hover:underline"
                  >
                      Zaloguj się!
                  </a>
              </p>
          </div>
        </div>
    )
}

export default SignUp;
import React from 'react'

type Props = {
    label: string;
    value: string | number;
    denominator?: string | number;
    unit?: string;
    colorValue?: boolean;
}

export default function CardParameter(props: Props) {
  return (
    <div className=' text-lg'>
        <span className=' text-neutral-500 dark:text-neutral-300'>
            {props.label + ": "}
        </span>
        <span className={`font-bold ${props.denominator && props.colorValue ? Number(props.value) < Number(props.denominator) ? "text-red-600 dark:text-red-400" : " text-green-600 dark:text-green-400" : ""}`}>
            {props.value + "" + (props.value === "Polski" ? " 🇵🇱" : props.value === "Angielski" ? " 🇬🇧" : "")}
        </span>
        {(props.denominator !== undefined) ? ("/"+props.denominator) : ""}
        <span className=''>{props.unit ? " " + props.unit : ""}</span>
    </div>
  )
}
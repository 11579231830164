import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Loading from '../../../layouts/Loading';
import EssayCard from '../../../components/EssayCard/EssayCard';
import { getEssaysSentByCurrentUser } from '../../../services/EssaysData';
import CardsLayout from '../../../layouts/CardsLayout';
import HeaderContainer from '../../../components/Outputs/HeaderContainer';
import { Essay } from '../../../assets/types';

export default function ViewPastEssays() {

    const [essays, setEssays] = useState<Essay[]>( [] as Essay[]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        getEssaysSentByCurrentUser().then((items)=>{
            if(items) {
                setEssays(items)
                setLoading(false);
            }
        });
    }, []);

    function handleChoose (eid: number) {
        navigate(`/student/view/${eid}`);
    }

    if (loading)
    {
        return <Loading/>;
    }

    return (

        <>
            <HeaderContainer>
                {essays?.length === 0 ? ("Brak dostępnych prac") : ("Tutaj znajdują się Twoje prace czekające na sprawdzenie oraz te, które już zostały sprawdzone.")}
            </HeaderContainer>
            <CardsLayout>
                {essays?.map((essay, i)=>(
                    <EssayCard 
                        key={i}
                        exam={essay.product.exam} 
                        generation={essay.product.generation} 
                        id={essay.id} 
                        subject={essay.product.subject} 
                        level={essay.product.level} 
                        action={()=>handleChoose(essay.id)}
                        type={essay.task.subtype.name} 
                        writingTime={essay.product.max_time}
                        minWords={essay.product.min_words}
                        words = { !essay.content || !essay.content === undefined ? 0 : essay.content.trim().split(" ").length }
                        status={essay.status}
                        cardFunction={"ready"}
                        actionText={"Otwórz!"}
                    />  
                ))}
            </CardsLayout>
        </>
    )
}

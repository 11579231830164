import React, { useEffect, useState } from 'react'
import Loading from '../layouts/Loading';
import { supabase } from '../services/client';
import { useAuth } from '../context/AuthContext';
import { getEssaysForCurrentUser } from '../services/EssaysData';

export default function Time() {

    const [viewData, setViewData] = useState<string | null>(null);
    const { user, signIn } = useAuth();
    //const [loading, setLoading] = useState(true);

    /* async function handleClick() {
        //signin
        console.log(user);
    } */

    const handleClick = async () => {
        try {
            const { data: {session}} = await supabase.auth.getSession();
          console.log(session?.user) 
        } catch (error) {
            console.log(error);
        }
      };

    useEffect(()=>{

        console.log(user);

        /* supabase.from('essays').select('content').eq("id", "10110").single().then(({data, error})=>{
            if (data && data.content as string) {
                setViewData(data.content);
            }
        }) */
    },[])

    //if (loading) return <Loading />
    return (
        <div>
            <div>
                {viewData}
            </div>
            <button onClick={handleClick}>
                Click
            </button>
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import { Subtype, Task } from '../assets/types';
import { getEssayProduct } from '../services/EssaysData'
import { getSubtypes, getTasksPublicForProduct } from '../services/TasksData';
import BigInput from '../components/Inputs/BigInput';
import CheckInput from '../components/Inputs/CheckInput';
import HeaderContainer from '../components/Outputs/HeaderContainer';
import Loading from './Loading';
import SelectInput from '../components/Inputs/SelectInput';
import SmallInput from '../components/Inputs/SmallInput';
import { getCurrentUser } from '../services/UsersData';
import { User } from '@supabase/supabase-js';

type Props = {
    essayId: number;
    chosenTask: number;
    setChosenTask: React.Dispatch<React.SetStateAction<number>>;
    chosenSubtype: number;
    setChosenSubtype: React.Dispatch<React.SetStateAction<number>>;
    newTask: Task;
    setNewTask: React.Dispatch<React.SetStateAction<Task>>;
    useOwn: boolean;
    setUseOwn: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ChooseTask({essayId, chosenTask, setChosenTask, chosenSubtype, setChosenSubtype, newTask, setNewTask, useOwn, setUseOwn}: Props) {

    const [tasks, setTasks] = useState<Task[]>([] as Task[]);
    const [loading, setLoading] = useState(true);
    const [task, setTask] = useState(tasks.find((task)=>(task.id === Number(chosenTask))));
    const [isJT, setIsJT] = useState(false);
    const [user, setUser] = useState({} as User);
    const [subtypes, setSubtypes] = useState([] as Subtype[]);

    function handleChange (event: React.ChangeEvent<HTMLSelectElement>) {
        setChosenTask(Number(event.target.value))
        setChosenSubtype(tasks.find((task)=>(task.id === Number(event.target.value)))?.subtype_id ?? 0)
    }

    function updateTask (id: number, data: Partial<Task>) {
        let copy = newTask;
        Object.assign(copy, data);
        setNewTask(copy);
    }

    useEffect(() => {
        getCurrentUser().then((user)=>{
            if( user ) {
                setUser(user);
                getEssayProduct(essayId.toString()).then((product)=>{
                    if(product && product.id) {
                        if(product.is_fragment_required === true || product.is_fragment_required === false){
                            getTasksPublicForProduct(product.id).then((result)=>{
                                if(result) {
                                    setTasks(result);
                                    setChosenTask(result.length === 0 ? -1 : result[0].id);
                                    setChosenSubtype(result.length === 0 ? -1 : result[0].subtype?.id)
                                    setIsJT(product.is_fragment_required);
                                    getSubtypes().then((results)=>{
                                        //console.log(result)
                                        if(results) {
                                            setSubtypes(results)
                                            setLoading(false);
                                        }
                                    })
                                }
                                else
                                {
                                    setLoading(false);
                                }
                            })
                        }
                    }
                })
            }
        })
    },[essayId])

    useEffect(()=>{
        setTask(tasks.find((task)=>(task.id === Number(chosenTask))));
    }, [chosenTask])
    
    if (loading ) return <Loading />;
    else return (
        <div className='mt-2 w-full px-3 py-2 rounded-xl border border-neutral-300 dark:border-neutral-600 font-medium text-sm dark:text-neutral-200 text-neutral-800'>
            <div className='flex flex-col gap-2 mb-1'>
                <HeaderContainer>
                    Wybierz polecenie
                </HeaderContainer>
                <div className='w-full h-max flex flex-row gap-2 text-neutral-700 dark:text-neutral-200 font-normal text-md'>
                    <div className='flex flex-col justify-center'>
                        <span className='h-fit'>lub dodaj swoje własne polecenie </span>
                    </div>
                    <div className='flex flex-col justify-center'>
                        <label className="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" className="sr-only peer" checked={useOwn} onChange={()=>{setUseOwn(!useOwn)}} />
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
                        </div>
                        </label>
                    </div>
                </div>
                { !useOwn ? (<><select id={"tasks"} onChange={handleChange} value={chosenTask} className="px-3 py-2 border rounded-lg dark:bg-neutral-600 bg-neutral-100 text-neutral-800 dark:text-neutral-200 border-neutral-300 dark:border-neutral-500" style={{appearance: "none"}}>
                    <optgroup label={"Polecenia CKE"}>
                    {
                        tasks.map((option, i)=>{
                            if(option.admin_made && option.is_public) {
                                return (
                                    <option
                                        key={i}
                                        value={option.id}
                                    >
                                        {option.task_source} {isJT ? "- " + (option as Task).fragment_title : <></>} - {option.subtype?.name ?? ""}
                                    </option>
                                )
                            }
                        })
                    }
                    </optgroup>
                    <optgroup label={"Twoje polecenia"}>
                    {
                        tasks.map((option, i)=>{
                            if(option.owner_id === user.id) {
                                return (
                                    <option
                                        key={i}
                                        value={option.id}
                                    >
                                        {option.task_source} {isJT ? "- " + (option as Task).fragment_title : <></>} - {option.subtype?.name ?? ""}
                                    </option>
                                )
                            }
                        })
                    }
                    </optgroup>
                    {/* <optgroup label={"Publiczne prace innych użytkowników"}>
                    {
                        tasks.map((option, i)=>{
                            if(option.is_public && !option.admin_made) {
                                return (
                                    <option
                                        key={i}
                                        value={option.id}
                                    >
                                        {option.subtype?.name ?? ""} {!isJT ? "- " + (option as Task).fragment_title : <></>} - {option.task_source}
                                    </option>
                                )
                            }
                        })
                    }
                    </optgroup> */}
                </select>
                    {task ? (
                    <>
                        <HeaderContainer>
                            Aktualnie wybrane
                        </HeaderContainer>
                        <div className='font-extrabold'>
                            Typ pracy 
                            <span className=' font-thin'>
                                {": " + task?.subtype?.name }
                            </span>
                        </div>
                        <div className='font-extralight'>
                            {task?.task}
                        </div>
                    </>)
                    : ""}
                    {isJT && task ? (
                        <>
                            <div className='font-extrabold'>
                                "{(task as Task)?.fragment_title}" - {(task as Task)?.fragment_author}
                            </div>
                            <div className='font-extralight whitespace-pre-wrap text-justify text-xs md:text-sm'>
                                {(task as Task)?.fragment_content}
                            </div>
                            <div className='font-bold'>
                                {(task as Task)?.fragment_source}
                            </div>
                        </>
                    ) : ""}</>
                ) : ""}
                { useOwn ? (
                    <>
                        <div className='flex flex-col gap-4'>
                            <BigInput id={""} label={"Polecenie"} description={"Zapisz całe polecenie."} valueName={"task"} firstText={newTask.task} save={updateTask} instantSaving={true}/>
                            <SelectInput id={""} label={"Typ pracy"} valueName={"subtype_id"} firstValue={subtypes[0].id} save={updateTask} options={subtypes} />
                            <CheckInput id={""} label={"Widoczność"}description={"Czy to polecenie może być udostępnione innym użytkownikom?"} valueName={"is_public"} firstValue={newTask.is_public} save={updateTask} />
                            {isJT ? (
                            <>
                                <div className='flex flex-row w-full gap-4'>
                                    <div className='w-1/2'><SmallInput id={""} label={"Autor"} valueName={"fragment_author"} firstText={newTask.fragment_author} save={updateTask} instantSaving={true} /></div>
                                    <div className='w-1/2'><SmallInput id={""} label={"Tytuł"} valueName={"fragment_title"} firstText={newTask.fragment_title} save={updateTask} instantSaving={true} /></div>
                                </div>
                                <SmallInput id={""} label={"Źródło fragmentu"} description={"Jest ono zazwyczaj podane na dole ostatniej strony egzaminu z danym fragmentem."} valueName={"fragment_source"} firstText={newTask.fragment_source} save={updateTask} instantSaving={true} />
                            </>
                            ) : ""}
                            <SmallInput id={""} label={"Źródło tego polecenia"} description={"Podaj źródło tego polecenia np. Matura CKE maj 2022."} valueName={"task_source"} firstText={newTask.task_source} save={updateTask} instantSaving={true} />
                            {isJT ? (
                            <BigInput id={""} label={"Fragment"} description={"Przepisz fragment tekstu kultury. Pamiętaj o formatowaniu, zwałaszcza w przypadku wierszy."} valueName={"fragment_content"} firstText={newTask.fragment_content} save={updateTask} instantSaving={true} />
                            ) : ""}
                        </div>
                    </>
                ) : ""
                }
            </div>
        </div>
    )
}
import { Transition } from '@headlessui/react';
import React, { useEffect, useState } from 'react'

type tilePropsType = {
    colspan: number;
    rowspan: number;
    children: JSX.Element;
}

export default function DashboardTile(props :tilePropsType) {
    // eslint-disable-next-line
    //let tileClassNames = "p-6 w-"+ (48*colspan+4*(colspan-1)).toString()+ " h-" + (48*rowspan+4*(rowspan-1)).toString() + " bg-slate-200 rounded-3xl" + " col-span-" + colspan + " row-span-"+rowspan;
    
    // eslint-disable-next-line
    const oneTileDimension = 48;
    const gap= 16;

    const comonClasses = " flex flex-col p-4 bg-neutral-200 dark:bg-neutral-700 rounded-3xl ease-in duration-150 hover:scale-105 drop-shadow-sm border border-neutral-300 dark:border-neutral-600 ";

    const tileClassNames = (props.colspan===1 && props.rowspan===1) ?  "md:w-48 md:h-48 col-span-1 row-span-1 w-40 h-40" : 

    (props.colspan===2 && props.rowspan===1) ? "md:w-[400px] md:h-48 col-span-2 row-span-1 h-40 w-[336px]" :

    (props.colspan===1 && props.rowspan===2) ? "md:w-48 md:h-[400px] col-span-1 row-span-2 h-[336px] w-40" :

    (props.colspan===2 && props.rowspan===2) ? "md:w-[400px] md:h-[400px]  col-span-2 row-span-2 h-[336px] -[336px]" :

    "w-48 h-48 col-span-1 row-span-1";

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
    }, [])

    return (
        <Transition
            show={loading}
            enter="transition-opacity duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            className={tileClassNames}
        >
            <div className={tileClassNames + comonClasses}>
                {props.children}
            </div>
        </Transition>
    )
}

import React from 'react'

type Props = {
    children: React.ReactNode
}

export default function PaperBg({ children }: Props) {
  return (
    <div id="paper-bg" className='relative w-fit p-10 rounded-2xl bg-neutral-200 dark:bg-neutral-700 border border-neutral-300 dark:border-neutral-600'>
        {children}
    </div>
  )
}

import React from 'react'
import { statusCodes } from '../../data/constants';
import StatusBar from './StatusBar';

type Props = {
    status: number;
}

export default function CardStatus(props: Props) {

    const maxStatus = statusCodes.length - 1;

    return (
        <div className='my-2'>
            <div className='text-lg md:text-md'>
                <span className='font-semibold'>{statusCodes[props.status]}</span>
            </div>
            <div className='mt-1'>
                <StatusBar percentValue={(props.status/maxStatus)*100}/>
            </div>
        </div>
    )
}

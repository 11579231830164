import React from 'react'

export default function CardsLayout({children}:any) {
  return (
/*     <div className='flex w-fit justify-center m-auto h-full pt-4 pb-24 md:pl-52 bg-neutral-50 dark:bg-neutral-800 text-neutral-800 dark:text-neutral-50'>
 */     <div className='w-max grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-flow-cols-4 2xl:grid-cols-5 gap-4'>
            {children}
        </div>
/*     </div>
 */  )
}

import React, { useEffect, useState } from 'react'
import { Subtype, Task } from '../../assets/types';

type Props = {
    id: string;
    label: string;
    description?: string;
    valueName: string;
    firstValue: number;
    options: Subtype[];
    save(id: number, updatedData: Partial<Task>): Promise<void> | void
}

export default function SelectInput({id, label, valueName, firstValue, description, save, options}: Props) {

    const [value, setValue] = useState(firstValue);

    function handleChange (event: React.ChangeEvent<HTMLSelectElement>) {
        setValue(Number(event.target.value))
        save(Number(id), {[valueName]: event.target.value})
    }

    useEffect(()=>{
        save(Number(id), {[valueName]: firstValue})
    },[id])

    return (
      <div className='flex flex-col gap-2'>
            <div className='text-neutral-700 dark:text-neutral-200 font-semibold text-xl'>
                {label}
            </div>
            { description ? (
            <div className='text-neutral-700 dark:text-neutral-200 font-normal text-md'>
                {description}
            </div>
            ) : ("")}
            <select id={"options"} onChange={handleChange} value={value} className="px-3 py-2 border rounded-lg dark:bg-neutral-600 bg-neutral-100 text-neutral-800 dark:text-neutral-200 border-neutral-300 dark:border-neutral-500" style={{appearance: "none"}}>
            {
                options.map((option, i)=>(
                    <option
                        key={i}
                        value={option.id}
                    >
                        {option.name}
                    </option>
                ))
            }
            </select>
      </div>
    )
}
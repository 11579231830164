import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Loading from '../../../layouts/Loading';
import EssayCard from '../../../components/EssayCard/EssayCard';
import WriteEssayTicket from '../../../components/EssayCard/WriteEssayTicket';
import { getEssaysBoughtByCurrentUser } from '../../../services/EssaysData';
import CardsLayout from '../../../layouts/CardsLayout';
import HeaderContainer from '../../../components/Outputs/HeaderContainer';
import { Essay } from '../../../assets/types';

export default function ChooseWrite() {

    const [essays, setEssays] = useState<Essay[]>([] as Essay[]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        getEssaysBoughtByCurrentUser().then((items)=>{
            if(items) 
            {
                setEssays(items)
                setLoading(false);
            }
        });
    }, []);

    function handleChoose (eid: number, status: number) {
        if(status === 1 && status < 3 ) {
            navigate(`/student/write/before-you-proceed/${eid}`);
        }
        else if( status !== 1 && status < 3)
        {
            navigate(`/student/write/${eid}`);
        }
    }

    if (loading)
    {
        return <Loading/>;
    }

    return (

        <>
            <HeaderContainer>
                {essays?.length === 0 ? ("Brak dostępnych prac") : ("Teraz możesz napisać rozprawkę korzystając z biletu lub dokończyć rozpoczętą już pracę.")}
            </HeaderContainer>
            <CardsLayout>
                {essays?.map((essay, i)=>{
                    return (essay.status === 1) ? 
                    <WriteEssayTicket 
                        key={i}
                        exam={essay.product.exam} 
                        generation={essay.product.generation} 
                        eid={essay.id} 
                        subject={essay.product.subject} 
                        level={essay.product.level} 
                        action={()=>handleChoose(essay.id, essay.status)}
                        type={essay.product.type} 
                    /> : ""
                })}
            </CardsLayout>
            <CardsLayout>
                {essays?.map((essay, i)=>{
                    return (essay.status === 2) ? 
                    <EssayCard 
                        key={i}
                        exam={essay.product.exam} 
                        generation={essay.product.generation} 
                        id={essay.id} 
                        subject={essay.product.subject} 
                        level={essay.product.level} 
                        action={()=>handleChoose(essay.id, essay.status)}
                        type={essay.task.subtype.name} 
                        writingTime={essay.product.max_time}
                        minWords={essay.product.min_words}
                        words = { !essay.content || !essay.content === undefined ? 0 : essay.content.trim().split(" ").length }
                        cardFunction={"write"}
                        actionText={"Napisz"}
                    />  : ""
                })}
            </CardsLayout>
        </>
    )
}

import React from 'react'
import { useAuth } from '../../context/AuthContext'

const Home = () => {
  
    const { signOut, auth, user } = useAuth();

    const handleSignOut = async (event: any) => {
        signOut();  
    }

    return (
    <div className=' text-white p-4 px-6 bg-neutral-600'>
        <a href='/' > Home </a>
        <a href='/student' > Panel </a>
        <a href='/signin' > Zaloguj się </a>
        <a href='/signup' > Zarejestruj się </a>
        {auth && <button onClick={handleSignOut}>Sign Out</button>}
        {auth && 
            <div style={{ fontSize: "24px" }}>
                You are logged in and your email address is {user?.email}
            </div> 
        }
    </div>
    )
}

export default Home;